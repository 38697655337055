import { Ability, Action, Subject } from '../ability';

class SummaryAbility implements Ability {
  subject: Subject;
  action: Action;

  constructor(action: Action) {
    this.subject = 'Summary';
    this.action = action;
  }
}

export const SummaryProjectReadAbility = new SummaryAbility(
  'viewProjectSummary' as Action,
);
export const SummaryProjectCalendarAbility = new SummaryAbility(
  'viewProjectCalendar' as Action,
);

export const summaryAbilities = [
  SummaryProjectReadAbility,
  SummaryProjectCalendarAbility,
];
export default summaryAbilities;
