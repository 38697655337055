import axios, { AxiosInstance } from 'axios';
import { AuxiliaryAudioResponse } from './model/auxiliaryAudioResponse';
import { PostAuxiliaryAudioRequest } from './model/postAuxiliaryAudioRequest';
import { FileUrlResponse } from '../files/fileUrlResponse';
import {
  AuxiliaryAudioType,
  UploadAuxiliaryAudioUrls,
} from './model/auxilaryAudioTypes';

const API_URL = process.env.REACT_APP_API_URL;
const PROJECT_URL = (projectId: string) => `${API_URL}/projects/${projectId}`;

const CREATE_GETLIST_AUXILIARY_AUDIO_URL = (projectId: string) =>
  `${PROJECT_URL(projectId)}/auxiliary-audios`;

const UPDATE_DOWNLOAD_AUXILIARY_AUDIO_BY_URL = (
  projectId: string,
  auxiliaryAudioId: string,
) => `${PROJECT_URL(projectId)}/auxiliary-audios/${auxiliaryAudioId}`;

export async function getAuxiliaryAudios(
  projectId: string,
  type: AuxiliaryAudioType,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<AuxiliaryAudioResponse[]> {
  const response = await axiosInstance.get(
    CREATE_GETLIST_AUXILIARY_AUDIO_URL(projectId),
    { params: { type } },
  );
  return response.data;
}

export async function getAuxiliaryAudio(
  projectId: string,
  id: string,
  type: AuxiliaryAudioType,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<FileUrlResponse> {
  const response = await axiosInstance.get(
    UPDATE_DOWNLOAD_AUXILIARY_AUDIO_BY_URL(projectId, id),
    { params: { type } },
  );
  return response.data;
}

export async function getUploadAuxiliaryAudioUrls(
  projectId: string,
  type: AuxiliaryAudioType,
  filename: string,
  parts: number,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<UploadAuxiliaryAudioUrls> {
  const response = await axiosInstance.post(
    CREATE_GETLIST_AUXILIARY_AUDIO_URL(projectId),
    {
      name: filename,
      parts,
    },
    { params: { type } },
  );
  return response.data;
}

export async function completeUploadAuxiliaryAudio(
  projectId: string,
  type: AuxiliaryAudioType,
  createDto: PostAuxiliaryAudioRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<AuxiliaryAudioResponse> {
  const response = await axiosInstance.put(
    UPDATE_DOWNLOAD_AUXILIARY_AUDIO_BY_URL(projectId, createDto.id),
    createDto,
    { params: { type } },
  );
  return response.data;
}
