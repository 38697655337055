import { Ability, Action, Subject } from '../ability';

class CastingsAbility implements Ability {
  subject: Subject;
  action: Action;

  constructor(action: Action) {
    this.subject = 'Castings';
    this.action = action;
  }
}

export const CastingsReadAbility = new CastingsAbility('read');
export const CastingsCreateAbility = new CastingsAbility('create');
export const CastingsUpdateAbility = new CastingsAbility('update');
export const CastingsDeleteAbility = new CastingsAbility('delete');

export const CastingsAbilities = [
  CastingsReadAbility,
  CastingsCreateAbility,
  CastingsUpdateAbility,
  CastingsDeleteAbility,
];

export default CastingsAbilities;
