import axios, { AxiosInstance } from 'axios';
import {
  UploadedPart,
  UploadFileUrls,
} from 'src/contexts/UploaderContext/uploadFileModel';
import { PostRecordingRequest } from './model/postRecordingRequest';
import { RecordingResponse } from './model/recordingResponse';
import { DownloadAafResponse } from '../aaf/downloadAafResponse';
import { ClipResponseDto } from '../clips/model/clipResponse';
import { UpdateClipRequest } from '../clips/model/updateClipRequest';
import { UploadClipRequest } from '../clips/model/uploadClipRequest';
import { UploadClipResponse } from '../clips/model/uploadClipResponse';
import { CopyClipRequest } from '../clips/model/copyClipRequest';
import { CopyClipResponse } from '../clips/model/copyClipResponse';
import { CloseRecordingRequest } from './model/closeRecordingRequest';
import { PutRecordingRequest } from './model/updateRecordingRequest';
import { RecordClipResponse } from '../clips/model/recordClipResponse';
import { RecordClipRequestDto } from '../clips/model/recordClipRequest';
import { FinishRecordClipRequest } from '../clips/model/finishRecordClipRequest';

const API_URL = process.env.REACT_APP_API_URL;
const RECORDINGS_URL = (projectId: string) =>
  `${API_URL}/projects/${projectId}/recordings`;
const RECORDINGS_AAF_URL = (projectId: string, recordingId?: string) =>
  `${API_URL}/projects/${projectId}/recordings/aaf${
    recordingId ? '/' + recordingId : ''
  }`;
const RECORDING_BY_ID = (projectId: string, id: string, rest = '') =>
  `${RECORDINGS_URL(projectId)}/${id}${rest}`;
const UPLOAD_RECORDING_URL = `/upload`;
const CLIPS_URL = `/clips`;
const CLIPS_UPLOAD_URL = `${CLIPS_URL}/upload`;
const CLIPS_RECORD_URL = `${CLIPS_URL}/record`;
const CLIPS_FINISH_RECORD_URL = (clipId: string) =>
  `${CLIPS_URL}/${clipId}/finish-record`;
const CLIPS_RECORD_AUDIO_UPLOAD_URL = (audioId: string) =>
  `${CLIPS_URL}/${audioId}/upload`;
const CLIPS_COPY_URL = `${CLIPS_URL}/copy`;
const CLOSE_URL = `/close`;

export async function getRecordings(
  projectId: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<RecordingResponse[]> {
  const response = await axiosInstance.get(RECORDINGS_URL(projectId));
  return response.data;
}

export async function getRecording(
  projectId: string,
  id: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<RecordingResponse> {
  const response = await axiosInstance.get(RECORDING_BY_ID(projectId, id));
  return response.data;
}

export async function postRecording(
  projectId: string,
  recording: PostRecordingRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<RecordingResponse> {
  const response = await axiosInstance.post(
    RECORDINGS_URL(projectId),
    recording,
  );
  return response.data;
}

export async function updateRecording(
  projectId: string,
  recordingId: string,
  recording: PutRecordingRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<RecordingResponse> {
  const response = await axiosInstance.put(
    RECORDING_BY_ID(projectId, recordingId),
    recording,
  );
  return response.data;
}

export async function getUploadRecordingFileUrls(
  projectId: string,
  id: string,
  filename: string,
  parts: number,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<UploadFileUrls> {
  const response = await axiosInstance.post(
    RECORDING_BY_ID(projectId, id, UPLOAD_RECORDING_URL),
    {
      name: filename,
      parts,
    },
  );
  return response.data;
}

export async function completeUploadRecordingFile(
  projectId: string,
  id: string,
  key: string,
  uploadId: string,
  parts: UploadedPart[],
  axiosInstance: AxiosInstance = axios.create(),
): Promise<RecordingResponse> {
  const response = await axiosInstance.put(
    RECORDING_BY_ID(projectId, id, UPLOAD_RECORDING_URL),
    {
      key,
      uploadId,
      parts,
    },
  );
  return response.data;
}

export async function downloadClips(
  projectId: string,
  id: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<ClipResponseDto[]> {
  const response = await axiosInstance.get(
    RECORDING_BY_ID(projectId, id, CLIPS_URL),
  );
  return response.data;
}

export async function downloadSomeClips(
  projectId: string,
  id: string,
  clips: string[],
  axiosInstance: AxiosInstance = axios.create(),
): Promise<ClipResponseDto[]> {
  const response = await axiosInstance.post(
    RECORDING_BY_ID(projectId, id, CLIPS_URL),
    { clips },
  );
  return response.data;
}

export async function updateClips(
  projectId: string,
  id: string,
  clips: UpdateClipRequest[],
  axiosInstance: AxiosInstance = axios.create(),
): Promise<void> {
  const response = await axiosInstance.put(
    RECORDING_BY_ID(projectId, id, CLIPS_URL),
    clips,
  );
  return response.data;
}

export async function uploadClip(
  projectId: string,
  id: string,
  request: UploadClipRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<UploadClipResponse> {
  const response = await axiosInstance.post(
    RECORDING_BY_ID(projectId, id, CLIPS_UPLOAD_URL),
    request,
  );
  return response.data;
}

export async function createRecordClip(
  projectId: string,
  id: string,
  request: RecordClipRequestDto,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<RecordClipResponse> {
  const response = await axiosInstance.post(
    RECORDING_BY_ID(projectId, id, CLIPS_RECORD_URL),
    request,
  );
  return response.data;
}

export async function uploadAudioToRecordClip(
  projectId: string,
  id: string,
  audioId: string,
  index: number,
  audio: ArrayBuffer,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<void> {
  const formData = new FormData();
  formData.append('audio', new Blob([audio]));
  await axiosInstance.post(
    RECORDING_BY_ID(projectId, id, CLIPS_RECORD_AUDIO_UPLOAD_URL(audioId)),
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: { index },
    },
  );
}

export async function finishRecordClip(
  projectId: string,
  id: string,
  clipId: string,
  request: FinishRecordClipRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<ClipResponseDto> {
  const response = await axiosInstance.post(
    RECORDING_BY_ID(projectId, id, CLIPS_FINISH_RECORD_URL(clipId)),
    request,
  );
  return response.data;
}

export async function completeUploadClip(
  projectId: string,
  id: string,
  key: string,
  uploadId: string,
  parts: UploadedPart[],
  axiosInstance: AxiosInstance = axios.create(),
): Promise<RecordingResponse> {
  const response = await axiosInstance.put(
    RECORDING_BY_ID(projectId, id, CLIPS_UPLOAD_URL),
    {
      key,
      uploadId,
      parts,
    },
  );
  return response.data;
}

export async function copyClips(
  projectId: string,
  id: string,
  request: CopyClipRequest[],
  axiosInstance: AxiosInstance = axios.create(),
): Promise<CopyClipResponse[]> {
  const response = await axiosInstance.post(
    RECORDING_BY_ID(projectId, id, CLIPS_COPY_URL),
    request,
  );
  return response.data;
}

export async function downloadAaf(
  projectId: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<DownloadAafResponse[]> {
  const response = await axiosInstance.get(RECORDINGS_AAF_URL(projectId));
  return response.data;
}

export async function downloadRecordingAaf(
  projectId: string,
  id: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<DownloadAafResponse> {
  const response = await axiosInstance.get(RECORDINGS_AAF_URL(projectId, id));
  return response.data;
}

export async function downloadRecordingsAaf(
  projectId: string,
  recordings: string[],
  axiosInstance: AxiosInstance = axios.create(),
): Promise<DownloadAafResponse[]> {
  const response = await axiosInstance.post(RECORDINGS_AAF_URL(projectId), {
    recordings,
  });
  return response.data;
}

export async function closeRecording(
  projectId: string,
  id: string,
  closeRecordingRequest: CloseRecordingRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<RecordingResponse> {
  const response = await axiosInstance.post(
    RECORDING_BY_ID(projectId, id, CLOSE_URL),
    closeRecordingRequest,
  );
  return response.data;
}

export async function deleteRecording(
  projectId: string,
  id: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<RecordingResponse[]> {
  const response = await axiosInstance.delete(RECORDING_BY_ID(projectId, id));
  return response.data;
}
