import { useCallback, useState } from 'react';
import { createCtx } from 'src/utils/context';
import { WithChildrenProps } from 'src/utils/react';
import EARSLoader from './EARSLoader';

type LoaderContextType = {
  start: (text?: string) => void;
  end: () => void;
};

export const [LoaderContext, CurrentLoaderProvider] =
  createCtx<LoaderContextType>();

export const LoaderProvider = ({ children }: WithChildrenProps) => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState<string>();

  const start = useCallback((text?: string) => {
    setText(text ? text : '');
    setOpen(true);
  }, []);

  const end = useCallback(() => setOpen(false), []);

  return (
    <CurrentLoaderProvider
      value={{
        start,
        end,
      }}
    >
      <EARSLoader open={open} text={text} />
      {children}
    </CurrentLoaderProvider>
  );
};

export const useLoader = () => {
  return LoaderContext();
};
