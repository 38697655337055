import { Ability, Action, Subject } from '../ability';

class ContractAbility implements Ability {
  subject: Subject;
  action: Action;

  constructor(action: Action) {
    this.subject = 'Contracts';
    this.action = action;
  }
}

export const ContractViewAbility = new ContractAbility(
  'viewContract' as Action,
);
export const ContractFilesViewAbility = new ContractAbility(
  'viewContractFiles' as Action,
);
export const ContractFilesUploadAbility = new ContractAbility(
  'uploadContractFiles' as Action,
);
export const ContractFilesDownloadAbility = new ContractAbility(
  'downloadContractFiles' as Action,
);
export const ContractFilesUpdateAbility = new ContractAbility(
  'updateContractFiles' as Action,
);

export const ContractFilesDeleteAbility = new ContractAbility(
  'deleteContractFiles' as Action,
);

export const ContractClientViewAbility = new ContractAbility(
  'viewContractClient' as Action,
);
export const ContractUsersViewAbility = new ContractAbility(
  'viewContractUsers' as Action,
);

export const contractAbilities = [
  ContractViewAbility,
  ContractFilesUploadAbility,
  ContractFilesDownloadAbility,
  ContractFilesUpdateAbility,
  ContractFilesDeleteAbility,
  ContractFilesViewAbility,
  ContractClientViewAbility,
  ContractUsersViewAbility,
];

export default contractAbilities;
