import { PureAbility, AbilityClass, AbilityBuilder } from '@casl/ability';
import { useAuth } from '../AuthContext';
import { WithChildrenProps } from 'src/utils/react';
import { createCtx } from 'src/utils/context';
import { Ability, Action, Subject } from 'src/contexts/AbilityContext/ability';

export type ArsAbility = PureAbility<[Action, Subject]>;
export const ArsAbilityClass = PureAbility as AbilityClass<ArsAbility>;

export const [AbilityContext, CurrentAbilityContextProvider] =
  createCtx<AbilityContextType>();

interface AbilityContextType {
  has: (ability: Ability) => boolean;
  hasAny: (...abilities: Ability[]) => boolean;
}

export const AbilityProvider = ({ children }: WithChildrenProps) => {
  const { user } = useAuth();
  const { can, rules } = new AbilityBuilder(ArsAbilityClass);
  if (user?.profile) {
    user.profile.abilities.forEach((a) => can(a.action, a.subject));
  }

  const abilities = new ArsAbilityClass(rules);
  return (
    <CurrentAbilityContextProvider
      value={{
        has: (ability: Ability) =>
          abilities.can(ability.action, ability.subject),
        hasAny: (...hasAbilities: Ability[]) =>
          hasAbilities.some((a) => abilities.can(a.action, a.subject)),
      }}
    >
      {children}
    </CurrentAbilityContextProvider>
  );
};

export const useAbility = () => {
  return AbilityContext();
};
