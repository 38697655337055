import { useState } from 'react';
import { createCtx } from 'src/utils/context';
import { WithChildrenProps } from 'src/utils/react';
import { ProjectStatus } from '../services/projects/model/projectEnums';

type SidebarContextType = {
  sidebarToggle: boolean;
  toggleSidebar: () => void;
  closeSidebar: () => void;
  projectId: string | undefined;
  setSidebarProject: (id: string | undefined) => void;
  currentTab: string;
  setCurrentTab: (tab: string) => void;
  projectStatus: ProjectStatus | undefined;
  setSidebarProjectStatus: (status: ProjectStatus | undefined) => void;
};

export const [SidebarContext, CurrentSidebarProvider] =
  createCtx<SidebarContextType>();

export const SidebarProvider = ({ children }: WithChildrenProps) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [projectId, setProjectId] = useState<string>();
  const [projectStatus, setProjectStatus] = useState<ProjectStatus>();
  const [currentTab, setCurrentTab] = useState<string>('files');

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  const setSidebarProject = (id: string | undefined) => {
    setProjectId(id);
  };

  const setSidebarProjectStatus = (status: ProjectStatus | undefined) => {
    setProjectStatus(status);
  };

  return (
    <CurrentSidebarProvider
      value={{
        sidebarToggle,
        toggleSidebar,
        closeSidebar,
        projectId,
        setSidebarProject,
        currentTab,
        setCurrentTab,
        projectStatus,
        setSidebarProjectStatus,
      }}
    >
      {children}
    </CurrentSidebarProvider>
  );
};
