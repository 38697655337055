import axios, { AxiosInstance } from 'axios';
import { ReviewResponse } from './model/reviewResponse';
import { DownloadAafResponse } from '../aaf/downloadAafResponse';
import { ClipResponseDto } from '../clips/model/clipResponse';
import { ReviewFilters } from './model/reviewFilters';

const API_URL = process.env.REACT_APP_API_URL;
const REVIEWS_URL = (projectId: string) =>
  `${API_URL}/projects/${projectId}/reviews`;
const REVIEW_BY_EDITION_ID = (
  projectId: string,
  editionId: string,
  rest = '',
) => `${REVIEWS_URL(projectId)}/edition/${editionId}${rest}`;
const REVIEWS_AAF_URL = (projectId: string, editionId?: string) =>
  `${API_URL}/projects/${projectId}/reviews${
    editionId ? `/edition/${editionId}` : ''
  }/aaf`;
const CLIPS_URL = `/clips`;
const SEND_RETAKES_URL = `/sendRetakes`;

export async function getReviews(
  projectId: string,
  axiosInstance: AxiosInstance = axios.create(),
  filters?: ReviewFilters,
): Promise<ReviewResponse[]> {
  const response = await axiosInstance.get(REVIEWS_URL(projectId), {
    params: filters,
  });
  return response.data;
}

export async function getReviewByEdition(
  projectId: string,
  editionId: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<ReviewResponse> {
  const response = await axiosInstance.get(
    REVIEW_BY_EDITION_ID(projectId, editionId),
  );
  return response.data;
}

export async function downloadClips(
  projectId: string,
  editionId: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<ClipResponseDto[]> {
  const response = await axiosInstance.get(
    REVIEW_BY_EDITION_ID(projectId, editionId, CLIPS_URL),
  );
  return response.data;
}

export async function downloadSomeClips(
  projectId: string,
  id: string,
  clips: string[],
  axiosInstance: AxiosInstance = axios.create(),
): Promise<ClipResponseDto[]> {
  const response = await axiosInstance.post(
    REVIEW_BY_EDITION_ID(projectId, id, CLIPS_URL),
    { clips },
  );
  return response.data;
}

export async function downloadAllReviewsAaf(
  projectId: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<DownloadAafResponse> {
  const response = await axiosInstance.get(REVIEWS_AAF_URL(projectId));
  return response.data;
}

export async function downloadReviewsAaf(
  projectId: string,
  editions: string[],
  axiosInstance: AxiosInstance = axios.create(),
): Promise<DownloadAafResponse> {
  const response = await axiosInstance.post(REVIEWS_AAF_URL(projectId), {
    editions,
  });
  return response.data;
}

export async function sendRetakes(
  projectId: string,
  editionId: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<ReviewResponse> {
  const response = await axiosInstance.post(
    REVIEW_BY_EDITION_ID(projectId, editionId, SEND_RETAKES_URL),
  );
  return response.data;
}
