import { Ability, Action, Subject } from '../ability';

class DeliveryAbility implements Ability {
  subject: Subject;
  action: Action;

  constructor(action: Action) {
    this.subject = 'Deliveries';
    this.action = action;
  }
}

export const DeliveryReadAbility = new DeliveryAbility('read');
export const DeliveryCreateAbility = new DeliveryAbility('create');
export const DeliveryUpdateAbility = new DeliveryAbility('update');
export const DeliveryUploadAudioAbility = new DeliveryAbility(
  'uploadClip' as Action,
);
export const DeliveryDownloadAudioAbility = new DeliveryAbility(
  'downloadClip' as Action,
);

export const deliveriesAbilities = [
  DeliveryReadAbility,
  DeliveryCreateAbility,
  DeliveryUploadAudioAbility,
  DeliveryUpdateAbility,
];
export default deliveriesAbilities;
