import { Ability, Action, Subject } from '../ability';

class SamplesAbility implements Ability {
  subject: Subject;
  action: Action;

  constructor(action: Action) {
    this.subject = 'Samples';
    this.action = action;
  }
}

export const SamplesReadAbility = new SamplesAbility('read');
export const SamplesCreateAbility = new SamplesAbility('create');
export const SamplesUpdateAbility = new SamplesAbility('update');
export const SamplesDeleteAbility = new SamplesAbility('delete');

export const SamplesAbilities = [
  SamplesReadAbility,
  SamplesCreateAbility,
  SamplesUpdateAbility,
  SamplesDeleteAbility,
];
export default SamplesAbilities;
