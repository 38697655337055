import axios, { AxiosInstance } from 'axios';
import {
  CreateSharedAudioTokenResponse,
  GetSharedAudioUrlResponse,
} from './model/ createSharedAudioResponse';

const API_URL = process.env.REACT_APP_API_URL;
const SHARED_AUDIO_URL = (rest = '') => `${API_URL}/shared-files/audio${rest}`;

export async function createSharedToken(
  projectId: string,
  audioId: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<CreateSharedAudioTokenResponse> {
  const response = await axiosInstance.post(
    SHARED_AUDIO_URL(`/${projectId}/${audioId}`),
  );
  return response.data;
}

export async function getSharedAudioUrl(
  shareToken: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<GetSharedAudioUrlResponse> {
  const response = await axiosInstance.get(SHARED_AUDIO_URL(`/${shareToken}`));
  return response.data;
}
