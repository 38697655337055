import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { i18nextPlugin } from 'translation-check';

import commonEn from './translations/en/common.json';
import commonEs from './translations/es/common.json';
import commonBr from './translations/br/common.json';
import projectsEn from './translations/en/projects.json';
import projectsEs from './translations/es/projects.json';
import projectsBr from './translations/br/projects.json';
import deliveriesEn from './translations/en/deliveries.json';
import deliveriesEs from './translations/es/deliveries.json';
import deliveriesBr from './translations/br/deliveries.json';
import editionsEs from './translations/es/editions.json';
import editionsEn from './translations/en/editions.json';
import editionsBr from './translations/br/editions.json';
import filesEn from './translations/en/files.json';
import filesEs from './translations/es/files.json';
import filesBr from './translations/br/files.json';
import recordingsEs from './translations/es/recordings.json';
import recordingsEn from './translations/en/recordings.json';
import recordingsBr from './translations/br/recordings.json';
import reviewsEn from './translations/en/reviews.json';
import reviewsEs from './translations/es/reviews.json';
import reviewsBr from './translations/br/reviews.json';
import roomsEn from './translations/en/rooms.json';
import roomsEs from './translations/es/rooms.json';
import roomsBr from './translations/br/rooms.json';
import standardsEn from './translations/en/standards.json';
import standardsEs from './translations/es/standards.json';
import standardsBr from './translations/br/standards.json';
import studioEn from './translations/en/studio.json';
import studioEs from './translations/es/studio.json';
import studioBr from './translations/br/studio.json';
import accountEn from './translations/en/account.json';
import accountEs from './translations/es/account.json';
import accountBr from './translations/br/account.json';
import loginEn from './translations/en/login.json';
import loginEs from './translations/es/login.json';
import loginBr from './translations/br/login.json';
import securityEn from './translations/en/security.json';
import securityEs from './translations/es/security.json';
import securityBr from './translations/br/security.json';
import sidebarEn from './translations/en/sidebar.json';
import sidebarEs from './translations/es/sidebar.json';
import sidebarBr from './translations/br/sidebar.json';
import timesheetsEs from './translations/es/timesheets.json';
import timesheetsEn from './translations/en/timesheets.json';
import timesheetsBr from './translations/br/timesheets.json';
import auxiliaryAudiosEn from './translations/en/auxiliaryAudios.json';
import auxiliaryAudiosEs from './translations/es/auxiliaryAudios.json';
import auxiliaryAudiosBr from './translations/br/auxiliaryAudios.json';
import seriesEn from './translations/en/series.json';
import seriesEs from './translations/es/series.json';
import seriesBr from './translations/br/series.json';
import headerEn from './translations/en/header.json';
import headerEs from './translations/es/header.json';
import headerBr from './translations/br/header.json';
import reportsEn from './translations/en/reports.json';
import reportsEs from './translations/es/reports.json';
import reportsBr from './translations/br/reports.json';
import actionsEn from './translations/en/actions.json';
import actionsEs from './translations/es/actions.json';
import actionsBr from './translations/br/actions.json';
import clientsEn from './translations/en/clients.json';
import clientsEs from './translations/es/clients.json';
import clientsBr from './translations/br/clients.json';
import contractsBr from './translations/br/contracts.json';
import contractsEn from './translations/en/contracts.json';
import contractsEs from './translations/es/contracts.json';

/**
 * To show GUI for translations, add to url ?showTranslations.
 */

const resources = {
  en: {
    common: commonEn,
    projects: projectsEn,
    deliveries: deliveriesEn,
    editions: editionsEn,
    files: filesEn,
    recordings: recordingsEn,
    reviews: reviewsEn,
    rooms: roomsEn,
    standards: standardsEn,
    studio: studioEn,
    account: accountEn,
    login: loginEn,
    security: securityEn,
    sidebar: sidebarEn,
    timesheets: timesheetsEn,
    auxiliaryAudios: auxiliaryAudiosEn,
    series: seriesEn,
    header: headerEn,
    reports: reportsEn,
    actions: actionsEn,
    clients: clientsEn,
    contracts: contractsEn,
  },
  es: {
    common: commonEs,
    projects: projectsEs,
    deliveries: deliveriesEs,
    editions: editionsEs,
    files: filesEs,
    recordings: recordingsEs,
    reviews: reviewsEs,
    rooms: roomsEs,
    standards: standardsEs,
    studio: studioEs,
    account: accountEs,
    login: loginEs,
    security: securityEs,
    sidebar: sidebarEs,
    timesheets: timesheetsEs,
    auxiliaryAudios: auxiliaryAudiosEs,
    series: seriesEs,
    header: headerEs,
    reports: reportsEs,
    actions: actionsEs,
    clients: clientsEs,
    contracts: contractsEs,
  },
  br: {
    common: commonBr,
    projects: projectsBr,
    deliveries: deliveriesBr,
    editions: editionsBr,
    files: filesBr,
    recordings: recordingsBr,
    reviews: reviewsBr,
    rooms: roomsBr,
    standards: standardsBr,
    studio: studioBr,
    account: accountBr,
    login: loginBr,
    security: securityBr,
    sidebar: sidebarBr,
    timesheets: timesheetsBr,
    auxiliaryAudios: auxiliaryAudiosBr,
    series: seriesBr,
    header: headerBr,
    reports: reportsBr,
    actions: actionsBr,
    clients: clientsBr,
    contracts: contractsBr,
  },
};

if (process.env.NODE_ENV === 'development') {
  i18n.use(i18nextPlugin);
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    debug: process.env.NODE_ENV === 'development',
    resources,
  });

export default i18n;
