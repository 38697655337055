import {
  projects,
  recordings,
  editions,
  reviews,
  retakes,
  deliveries,
  standards,
  timesheets,
  samples,
  series,
  reports,
  summary,
  castings,
  publisherDelivery,
  publisherReviews,
  files,
  clients,
  contracts,
} from './abilities';

export type Action = 'create' | 'read' | 'update' | 'delete' | 'manage';
export type Subject =
  | 'Projects'
  | 'Rooms'
  | 'Reports'
  | 'Users'
  | 'Policies'
  | 'Profiles'
  | 'Recordings'
  | 'Editions'
  | 'Reviews'
  | 'Deliveries'
  | 'Retakes'
  | 'Standards'
  | 'Timesheets'
  | 'Samples'
  | 'Castings'
  | 'Files'
  | 'Series'
  | 'Summary'
  | 'Reports'
  | 'PublisherDelivery'
  | 'PublisherReview'
  | 'Clients'
  | 'Contracts'
  | 'all';

export interface Ability {
  readonly action: Action;
  readonly subject: Subject;
}

export const RoomReadAbility: Ability = {
  subject: 'Rooms',
  action: 'read',
};

export const UserReadAbility: Ability = {
  subject: 'Users',
  action: 'read',
};

export const PolicyReadAbility: Ability = {
  subject: 'Policies',
  action: 'read',
};

export const ProfileReadAbility: Ability = {
  subject: 'Profiles',
  action: 'read',
};

export const AdminAbility: Ability = {
  subject: 'all',
  action: 'manage',
};

const abilities = [
  ...projects.default,
  ...recordings.default,
  ...editions.default,
  ...reviews.default,
  ...retakes.default,
  ...deliveries.default,
  ...standards.default,
  ...timesheets.default,
  ...samples.default,
  ...castings.default,
  ...series.default,
  ...reports.default,
  ...summary.default,
  ...publisherDelivery.default,
  ...publisherReviews.default,
  ...files.default,
  ...clients.default,
  ...contracts.default,
  RoomReadAbility,
  AdminAbility,
];
export default abilities;
