import {
  alpha,
  Box,
  Hidden,
  IconButton,
  lighten,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';

import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import Logo from 'src/components/Logo';
import HeaderProfilebox from './Profilebox';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
`,
);

const Header = () => {
  const { toggleSidebar } = SidebarContext();
  const theme = useTheme();

  return (
    <HeaderWrapper
      display="flex"
      alignItems="center"
      sx={{
        boxShadow:
          theme.palette.mode === 'dark'
            ? `0 1px 0 ${alpha(
                lighten(theme.colors.primary.main, 0.7),
                0.15,
              )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
            : `0px 2px 8px -3px ${alpha(
                theme.colors.alpha.black[100],
                0.2,
              )}, 0px 5px 22px -4px ${alpha(
                theme.colors.alpha.black[100],
                0.1,
              )}`,
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent={'flex-start'}
        gap={2}
      >
        <Hidden lgUp>
          <IconButton color="primary" onClick={toggleSidebar}>
            <MenuTwoToneIcon fontSize="small" />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <Logo datatype="text" height={24} />
        </Hidden>
        <Hidden lgDown>
          <Logo height={40} />
        </Hidden>
      </Box>
      <Box display="flex" alignItems="center">
        <HeaderButtons />
        <Hidden lgUp>
          <HeaderProfilebox />
        </Hidden>
        <HeaderUserbox />
      </Box>
    </HeaderWrapper>
  );
};

export default Header;
