import { Ability, Action, Subject } from '../ability';

class FileAbility implements Ability {
  subject: Subject;
  action: Action;

  constructor(action: Action) {
    this.subject = 'Files';
    this.action = action;
  }
}

export const FileViewAbility = new FileAbility('viewProjectFile' as Action);
export const FileUploadAbility = new FileAbility('uploadProjectFile' as Action);
export const FileDownloadAbility = new FileAbility(
  'downloadProjectFile' as Action,
);

export const fileAbilities = [
  FileViewAbility,
  FileUploadAbility,
  FileDownloadAbility,
];

export default fileAbilities;
