import { IDBPDatabase, openDB } from 'idb';
import { useEffect, useState } from 'react';
import { createCtx } from 'src/utils/context';
import { WithChildrenProps } from 'src/utils/react';

const PEAKS_STORE = 'peaks';
const CHUNKS_STORE = 'chunks';
const CHUNKS_BY_ID_INDEX = 'chunksById';
const CHUNKS_BY_CLIPID_INDEX = 'chunksByClipIdIndex';
const PEAKS_BY_TIMESTAMP_INDEX = 'peaksByTimestampIndex';

type DatabaseContextType = {
  db: IDBPDatabase;
  peaksStore: string;
  chunksStore: string;
  chunksByIdIndex: string;
  chunksByClipIdIndex: string;
  peaksByTimestampIndex: string;
};

type DatabaseContextProps = {
  databaseName: string;
};

export const [DatabaseContext, CurrentDatabaseProvider] =
  createCtx<DatabaseContextType>();

export const DatabaseProvider = ({
  children,
  databaseName,
}: DatabaseContextProps & WithChildrenProps) => {
  const [db, setDb] = useState<IDBPDatabase>();

  useEffect(() => {
    const createDatabase = async () => {
      const db = await openDB(databaseName, 3, {
        upgrade: (db, oldVersion, newVersion, transaction) => {
          if (oldVersion < 1) {
            db.createObjectStore(PEAKS_STORE);
            const chunksStore = db.createObjectStore(CHUNKS_STORE, {
              keyPath: ['id', 'index'],
            });
            chunksStore.createIndex(CHUNKS_BY_ID_INDEX, 'id');
          }

          if (oldVersion < 2) {
            const chunksStore = transaction.objectStore(CHUNKS_STORE);
            chunksStore.createIndex(CHUNKS_BY_CLIPID_INDEX, 'clipId');
          }

          if (oldVersion < 3) {
            const peaksStore = transaction.objectStore(PEAKS_STORE);
            peaksStore.createIndex(PEAKS_BY_TIMESTAMP_INDEX, 'timestamp');
          }
        },
      });
      setDb(db);
    };
    createDatabase();
  }, []);

  if (!db) {
    return <></>;
  }

  return (
    <CurrentDatabaseProvider
      value={{
        db: db,
        peaksStore: PEAKS_STORE,
        chunksStore: CHUNKS_STORE,
        chunksByIdIndex: CHUNKS_BY_ID_INDEX,
        chunksByClipIdIndex: CHUNKS_BY_CLIPID_INDEX,
        peaksByTimestampIndex: PEAKS_BY_TIMESTAMP_INDEX,
      }}
    >
      {children}
    </CurrentDatabaseProvider>
  );
};

export const useDatabase = () => {
  return DatabaseContext();
};
