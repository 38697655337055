import axios, { AxiosInstance } from 'axios';
import { PostSeriesRequest } from './model/postSeriesRequest';
import { SeriesResponse } from './model/seriesResponse';
import { PutSeriesRequest } from './model/putSeriesRequest';

const API_URL = process.env.REACT_APP_API_URL;
const SERIES_URL = `${API_URL}/series`;
const SERIES_BY_ID = (id: string) => `${SERIES_URL}/${id}`;

export async function getSeries(
  axiosInstance: AxiosInstance = axios.create(),
): Promise<SeriesResponse[]> {
  const response = await axiosInstance.get(SERIES_URL);
  return response.data;
}

export async function getSerie(
  id: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<SeriesResponse> {
  const response = await axiosInstance.get(SERIES_BY_ID(id));
  return response.data;
}

export async function postSeries(
  serie: PostSeriesRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<SeriesResponse> {
  const response = await axiosInstance.post(SERIES_URL, serie);
  return response.data;
}

export async function putSeries(
  id: string,
  serie: PutSeriesRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<SeriesResponse> {
  const response = await axiosInstance.put(SERIES_BY_ID(id), serie);
  return response.data;
}
