import { useRef, useState } from 'react';
import {
  Box,
  Button,
  Hidden,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { useAuth } from 'src/contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { AvailableLanguages } from '../../../../services/iam/profiles/model/profileResponse';

const ProfileBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`,
);

const MenuProfileBox = styled(Box)(
  ({ theme }) => `
        padding: ${theme.spacing(2)};
`,
);

const ProfileBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`,
);

const ProfileBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.primary.main};
        display: block;
        max-width: 200px;
`,
);

function HeaderProfilebox() {
  const auth = useAuth();
  const { i18n } = useTranslation();
  const locale = i18n.language as AvailableLanguages;
  const user = auth.user;
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const profiles = user.unitsProfiles
    ? user.unitsProfiles.flatMap((up) =>
        up.profiles.map((p) => ({
          unit: {
            id: up.id,
            name: up.name,
          },
          profile: p,
        })),
      )
    : [];

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      {profiles.length === 0 && (
        <MenuProfileBox sx={{ minWidth: 100 }} display="flex">
          <ProfileBoxText>
            <ProfileBoxLabel variant="body1">Guest</ProfileBoxLabel>
          </ProfileBoxText>
        </MenuProfileBox>
      )}
      {profiles.length === 1 && (
        <MenuProfileBox sx={{ minWidth: 100 }} display="flex">
          <ProfileBoxText>
            <ProfileBoxLabel variant="body1" noWrap>
              {user.profile?.nameI18n[locale] || user.profile?.nameI18n.en} [
              {user.profile?.unit.name}]
            </ProfileBoxLabel>
          </ProfileBoxText>
        </MenuProfileBox>
      )}
      {profiles.length > 1 && (
        <>
          <ProfileBoxButton color="secondary" ref={ref} onClick={handleOpen}>
            <ProfileBoxText>
              <ProfileBoxLabel variant="body1" noWrap>
                {user.profile?.nameI18n[locale] || user.profile?.nameI18n.en} [
                {user.profile?.unit.name}]
              </ProfileBoxLabel>
            </ProfileBoxText>
            <Hidden smDown>
              <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
            </Hidden>
          </ProfileBoxButton>
          <Popover
            anchorEl={ref.current}
            onClose={handleClose}
            open={isOpen}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <List sx={{ p: 1 }} component="nav">
              {profiles.map((p) => (
                <ListItemButton
                  key={p.profile.id}
                  onClick={() => {
                    auth.setProfile(p.profile.id, p.unit.id);
                    handleClose();
                  }}
                >
                  <ListItemText
                    primary={`${
                      p.profile.nameI18n[locale] || p.profile.nameI18n.en
                    } [${p.unit.name}]`}
                  />
                </ListItemButton>
              ))}
            </List>
          </Popover>
        </>
      )}
    </>
  );
}

export default HeaderProfilebox;
