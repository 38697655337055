import axios, { AxiosInstance } from 'axios';
import {
  UploadedPart,
  UploadFileUrls,
} from 'src/contexts/UploaderContext/uploadFileModel';
import { PostEditionRequest } from './model/postEditionRequest';
import { EditionResponse } from './model/editionResponse';
import { PutEditionRequest } from './model/putEditionRequest';
import { EditionFilters } from './model/editionFilters';

const API_URL = process.env.REACT_APP_API_URL;
const EDITIONS_URL = (projectId: string) =>
  `${API_URL}/projects/${projectId}/editions`;
const EDITION_BY_ID = (projectId: string, id: string, rest = '') =>
  `${EDITIONS_URL(projectId)}/${id}${rest}`;
const UPLOAD_FILE_URL = `/uploadFile`;

export async function getEditions(
  projectId: string,
  axiosInstance: AxiosInstance = axios.create(),
  filters?: EditionFilters,
): Promise<EditionResponse[]> {
  const response = await axiosInstance.get(EDITIONS_URL(projectId), {
    params: filters,
  });
  return response.data;
}

export async function getEdition(
  projectId: string,
  id: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<EditionResponse> {
  const response = await axiosInstance.get(EDITION_BY_ID(projectId, id));
  return response.data;
}

export async function postEdition(
  projectId: string,
  edition: PostEditionRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<EditionResponse> {
  const response = await axiosInstance.post(EDITIONS_URL(projectId), edition);
  return response.data;
}

export async function putEdition(
  projectId: string,
  edition: PutEditionRequest,
  id: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<EditionResponse> {
  const response = await axiosInstance.put(
    EDITION_BY_ID(projectId, id),
    edition,
  );
  return response.data;
}

export async function getUploadEditionFileUrls(
  id: string,
  projectId: string,
  filename: string,
  parts: number,
  axiosInstance: AxiosInstance = axios.create(),
  clipId?: string,
): Promise<UploadFileUrls> {
  const upload_url = clipId ? `${UPLOAD_FILE_URL}/${clipId}` : UPLOAD_FILE_URL;
  const response = await axiosInstance.post(
    EDITION_BY_ID(projectId, id, upload_url),
    {
      name: filename,
      parts,
    },
  );
  return response.data;
}

export async function completeUploadEditionFile(
  id: string,
  projectId: string,
  key: string,
  uploadId: string,
  parts: UploadedPart[],
  axiosInstance: AxiosInstance = axios.create(),
): Promise<EditionResponse> {
  const response = await axiosInstance.put(
    EDITION_BY_ID(projectId, id, UPLOAD_FILE_URL),
    {
      key,
      uploadId,
      parts,
    },
  );
  return response.data;
}
