import { Box } from '@mui/material';
import HeaderLanguage from './Language';

function HeaderButtons() {
  return (
    <Box sx={{ mr: 1 }}>
      <Box sx={{ mx: 0.5 }} component="span">
        <HeaderLanguage />
      </Box>
    </Box>
  );
}

export default HeaderButtons;
