import { Ability, Action, Subject } from '../ability';

class PublisherDeliveryAbility implements Ability {
  subject: Subject;
  action: Action;

  constructor(action: Action) {
    this.subject = 'PublisherDelivery';
    this.action = action;
  }
}

export const PublisherDeliveryReadAbility = new PublisherDeliveryAbility(
  'read',
);
export const PublisherDeliveryCreateAbility = new PublisherDeliveryAbility(
  'create',
);
export const PublisherDeliveryUpdateAbility = new PublisherDeliveryAbility(
  'update',
);
export const PublisherDeliveryUploadAudioAbility = new PublisherDeliveryAbility(
  'uploadClip' as Action,
);

export const PublisherDeliveryDownloadAudioAbility =
  new PublisherDeliveryAbility('downloadClip' as Action);

export const PublisherDeliveryFinishProjectAbility =
  new PublisherDeliveryAbility('finishProject' as Action);

export const publisherdeliveryAbilities = [
  PublisherDeliveryReadAbility,
  PublisherDeliveryCreateAbility,
  PublisherDeliveryUploadAudioAbility,
  PublisherDeliveryUpdateAbility,
  PublisherDeliveryDownloadAudioAbility,
  PublisherDeliveryFinishProjectAbility,
];
export default publisherdeliveryAbilities;
