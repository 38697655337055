import axios, { AxiosInstance } from 'axios';
import { ProfileResponse } from './model/profileResponse';
import { PostProfileRequest } from './model/postProfileRequest';
import { PutProfileRequest } from './model/putProfileRequest';

const API_URL = process.env.REACT_APP_API_URL;
const PROFILES_URL = `${API_URL}/iam/profiles`;
const PROFILE_BY_ID = (id: string) => `${PROFILES_URL}/${id}`;

export async function getProfiles(
  axiosInstance: AxiosInstance = axios.create(),
): Promise<ProfileResponse[]> {
  const response = await axiosInstance.get(PROFILES_URL);
  return response.data;
}

export async function getProfile(
  id: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<ProfileResponse> {
  const response = await axiosInstance.get(PROFILE_BY_ID(id));
  return response.data;
}

export async function postProfile(
  profile: PostProfileRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<ProfileResponse> {
  const response = await axiosInstance.post(PROFILES_URL, profile);
  return response.data;
}

export async function putProfile(
  id: string,
  profile: PutProfileRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<ProfileResponse> {
  const response = await axiosInstance.put(PROFILE_BY_ID(id), profile);
  return response.data;
}
