import { ListSubheader, List } from '@mui/material';
import { useAbility } from 'src/contexts/AbilityContext';
import { useTranslation } from 'react-i18next';
import { MenuWrapper, SubMenuWrapper } from '../menuWrapper';
import projectMenuItems from './items';
import SidebarProjectMenuItem from './item';

interface SidebarProjectMenuProps {
  projectId: string;
}
const SidebarProjectMenu = ({ projectId }: SidebarProjectMenuProps) => {
  const ability = useAbility();
  const { t } = useTranslation(['sidebar']);
  return (
    <>
      {projectMenuItems(projectId).map((section, i) => {
        const items = section.items.filter(
          (i) => !i.ability || ability.has(i.ability),
        );
        return (
          items.length > 0 && (
            <MenuWrapper key={i}>
              <List
                component="div"
                subheader={
                  <ListSubheader component="div" disableSticky>
                    {t(section.header)}
                  </ListSubheader>
                }
              >
                <SubMenuWrapper>
                  <List component="div">
                    {items.map((item, j) => (
                      <SidebarProjectMenuItem
                        key={`${i}-${j}`}
                        name={t(item.name)}
                        link={item.link}
                        tab={item.tab}
                        badge={item.badge}
                        icon={item.icon}
                        disabled={item.disabled}
                      />
                    ))}
                  </List>
                </SubMenuWrapper>
              </List>
            </MenuWrapper>
          )
        );
      })}
    </>
  );
};

export default SidebarProjectMenu;
