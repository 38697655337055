import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/CloseTwoTone';
import { useSnackbar, SnackbarKey } from 'notistack';

interface SnackbarCloseButtonProps {
  snackKey: SnackbarKey;
}

const SnackbarCloseButton = ({ snackKey }: SnackbarCloseButtonProps) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackKey)}>
      <CloseIcon />
    </IconButton>
  );
};

export default SnackbarCloseButton;
