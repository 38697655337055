import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from 'src/components/Footer';

interface BaseLayoutProps {
  children?: ReactNode;
  footer?: boolean;
}

const BaseLayout: FC<BaseLayoutProps> = ({ children, footer = true }) => {
  return (
    <Box pb={footer ? 4.5 : 0}>
      {children || <Outlet />}
      {footer && <Footer />}
    </Box>
  );
};

export default BaseLayout;
