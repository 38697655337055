import axios, { AxiosInstance } from 'axios';
import { PostUserRequest } from './model/postUserRequest';
import { PutUserRequest } from './model/putUserRequest';
import { UserResponse } from './model/userResponse';
import { toBase64 } from 'src/utils/base64';
import { Validate2FARequest } from './model/validate2FARequest';
import { UserFilters } from './model/userFilters';
import { InviteUserRequest } from './model/inviteUserRequest';
import { GetUserInvite } from './model/getUserInviteResponse';
import { UserNotificationPreferences } from './model/userNotificationPreferences';

const API_URL = process.env.REACT_APP_API_URL;
const USERS_URL = `${API_URL}/iam/users`;
const INVITES_URL = `${USERS_URL}/invites`;
const INVITES_URL_BY_ID = (id: string) => `${INVITES_URL}/${id}`;
const ACCEPT_INVITE_URL = (token: string) => `${INVITES_URL}/${token}`;
const USERS_2FA_URL = `${USERS_URL}/2FA`;
const GENERATE_2FA_URL = `${USERS_2FA_URL}/generate`;
const VAIDATE_2FA_URL = `${USERS_2FA_URL}/validate`;
const USER_BY_ID = (id: string) => `${USERS_URL}/${id}`;
const USER_BY_PROFILE_ID = (profileId: string) =>
  `${USERS_URL}/profile/${profileId}`;
const NOTIFICATIONS_URL = `${USERS_URL}/notifications`;

export async function getUsers(
  axiosInstance: AxiosInstance = axios.create(),
  filters?: UserFilters,
): Promise<UserResponse[]> {
  const response = await axiosInstance.get(USERS_URL, { params: filters });
  return response.data;
}

export async function getUser(
  id: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<UserResponse> {
  const response = await axiosInstance.get(USER_BY_ID(id));
  return response.data;
}

export async function postUser(
  user: PostUserRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<UserResponse> {
  const response = await axiosInstance.post(USERS_URL, user);
  return response && response.data;
}

export async function putUser(
  id: string,
  user: PutUserRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<UserResponse> {
  const response = await axiosInstance.put(USER_BY_ID(id), user);
  return response.data;
}

export async function deleteUser(
  id: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<void> {
  await axiosInstance.delete(USER_BY_ID(id));
}

export async function generate2FA(
  axiosInstance: AxiosInstance = axios.create(),
): Promise<string> {
  const response = await axiosInstance.post(GENERATE_2FA_URL, null, {
    responseType: 'arraybuffer',
  });
  return toBase64(response.data);
}

export async function validate2FA(
  request: Validate2FARequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<void> {
  await axiosInstance.post(VAIDATE_2FA_URL, request);
}

export async function getUsersByProfile(
  profileId: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<UserResponse[]> {
  const response = await axiosInstance.get(USER_BY_PROFILE_ID(profileId));
  return response.data;
}

export async function inviteUser(
  request: InviteUserRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<void> {
  await axiosInstance.post(INVITES_URL, request);
}

export async function uninviteUser(
  id: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<void> {
  await axiosInstance.delete(INVITES_URL_BY_ID(id));
}

export async function getInvites(
  axiosInstance: AxiosInstance = axios.create(),
): Promise<GetUserInvite[]> {
  const response = await axiosInstance.get(INVITES_URL);
  return response.data;
}

export async function acceptInvite(
  token: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<void> {
  await axiosInstance.put(ACCEPT_INVITE_URL(token));
}

export async function getNotificationPreferences(
  axiosInstance: AxiosInstance = axios.create(),
): Promise<UserNotificationPreferences[]> {
  const response = await axiosInstance.get(NOTIFICATIONS_URL);
  return response.data;
}

export async function updateNotificationPreferences(
  notificationPreferences: UserNotificationPreferences[],
  axiosInstance: AxiosInstance = axios.create(),
): Promise<UserNotificationPreferences[]> {
  const response = await axiosInstance.put(
    NOTIFICATIONS_URL,
    notificationPreferences,
  );
  return response.data;
}
