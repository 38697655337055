import axios, { AxiosInstance } from 'axios';
import { PostStandardRequest } from './model/postStandardRequest';
import { PutStandardRequest } from './model/putStandardRequest';
import { StandardResponse } from './model/standardResponse';

const API_URL = process.env.REACT_APP_API_URL;
const STANDARDS_URL = `${API_URL}/standards`;
const STANDARD_BY_ID = (id: string) => `${STANDARDS_URL}/${id}`;

export async function getStandards(
  axiosInstance: AxiosInstance = axios.create(),
): Promise<StandardResponse[]> {
  const response = await axiosInstance.get(STANDARDS_URL);
  return response.data;
}

export async function getStandard(
  id: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<StandardResponse> {
  const response = await axiosInstance.get(STANDARD_BY_ID(id));
  return response.data;
}

export async function postStandard(
  standard: PostStandardRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<StandardResponse> {
  const response = await axiosInstance.post(STANDARDS_URL, standard);
  return response.data;
}

export async function putStandard(
  id: string,
  standard: PutStandardRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<StandardResponse> {
  const response = await axiosInstance.put(STANDARD_BY_ID(id), standard);
  return response.data;
}

export async function putActiveStandard(
  id: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<StandardResponse> {
  const response = await axiosInstance.put(`${STANDARDS_URL}/${id}/active`);
  return response.data;
}

export async function putInactiveStandard(
  id: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<StandardResponse> {
  const response = await axiosInstance.put(`${STANDARDS_URL}/${id}/inactive`);
  return response.data;
}

export async function deleteStandard(
  id: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<void> {
  await axiosInstance.delete(STANDARD_BY_ID(id));
}

export async function activeStandard(
  id: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<void> {
  await axiosInstance.delete(STANDARD_BY_ID(id));
}
