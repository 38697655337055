export enum ProjectStatus {
  PENDING_FILES = 'PENDING_FILES',
  PLANNED = 'PLANNED',
  PRODUCTION = 'PRODUCTION',
  CREATING_DELIVERY = 'CREATING_DELIVERY',
  READY_FOR_DELIVERY = 'READY_FOR_DELIVERY',
  ENDED = 'ENDED',
}

export enum ProjectFileType {
  BOOK = 'BOOK',
  PRONUNCIATION = 'PRONUNCIATION',
  CREDITS = 'CREDITS',
  CESSION_RIGHTS = 'CESSION_RIGHTS',
  PROJECT_DELIVERY = 'PROJECT_DELIVERY',
}

export enum ProjectFileStatus {
  PROCESSING = 'PROCESSING',
  OK = 'OK',
  ERROR = 'ERROR',
}

export enum Language {
  ENGLISH = 'en',
  SPANISH = 'es',
  GREEK = 'el',
  AFAR = 'aa',
  ABKHAZIAN = 'ab',
  AVESTAN = 'ae',
  AFRIKAANS = 'af',
  AKAN = 'ak',
  AMHARIC = 'am',
  ARAGONESE = 'an',
  ARABIC = 'ar',
  ASSAMESE = 'as',
  AVARIC = 'av',
  AYMARA = 'ay',
  AZERBAIJANI = 'az',
  BASHKIR = 'ba',
  BELARUSIAN = 'be',
  BULGARIAN = 'bg',
  BIHARI = 'bh',
  BISLAMA = 'bi',
  BAMBARA = 'bm',
  BENGALI = 'bn',
  TIBETAN = 'bo',
  BRETON = 'br',
  BOSNIAN = 'bs',
  CATALAN = 'ca',
  CHECHEN = 'ce',
  CHAMORRO = 'ch',
  CORSICAN = 'co',
  CREE = 'cr',
  CZECH = 'cs',
  CHURCH = 'cu',
  CHUVASH = 'cv',
  WELSH = 'cy',
  DANISH = 'da',
  GERMAN = 'de',
  DIVEHI = 'dv',
  DZONGKHA = 'dz',
  EWE = 'ee',
  ESPERANTO = 'eo',
  ESTONIAN = 'et',
  BASQUE = 'eu',
  PERSIAN = 'fa',
  FULAH = 'ff',
  FINNISH = 'fi',
  FIJIAN = 'fj',
  FAROESE = 'fo',
  FRENCH = 'fr',
  WESTERN_FRISIAN = 'fy',
  IRISH = 'ga',
  GAELIC = 'gd',
  GALICIAN = 'gl',
  GUARANI = 'gn',
  GUJARATI = 'gu',
  MANX = 'gv',
  HAUSA = 'ha',
  HEBREW = 'he',
  HINDI = 'hi',
  HIRI_MOTU = 'ho',
  CROATIAN = 'hr',
  HAITIAN = 'ht',
  HUNGARIAN = 'hu',
  ARMENIAN = 'hy',
  HERERO = 'hz',
  INTERLINGUA = 'ia',
  INDONESIAN = 'id',
  INTERLINGUE_OCCIDENTAL = 'ie',
  IGBO = 'ig',
  SICHUAN_YI_NUOSU = 'ii',
  INUPIAQ = 'ik',
  IDO = 'io',
  ICELANDIC = 'is',
  ITALIAN = 'it',
  INUKTITUT = 'iu',
  JAPANESE = 'ja',
  JAVANESE = 'jv',
  GEORGIAN = 'ka',
  KONGO = 'kg',
  KIKUYU_GIKUYU = 'ki',
  KUANYAMA_KWANYAMA = 'kj',
  KAZAKH = 'kk',
  KALAALLISUT_GREENLANDIC = 'kl',
  CENTRAL_KHMER = 'km',
  KANNADA = 'kn',
  KOREAN = 'ko',
  KANURI = 'kr',
  KASHMIRI = 'ks',
  KURDISH = 'ku',
  KOMI = 'kv',
  CORNISH = 'kw',
  KIRGHIZ_KYRGYZ = 'ky',
  LATIN = 'la',
  LUXEMBOURGISH = 'lb',
  GANDA = 'lg',
  LIMBURGAN = 'li',
  LINGALA = 'ln',
  LAO = 'lo',
  LITHUANIAN = 'lt',
  LUBA_KATANGA = 'lu',
  LATVIAN = 'lv',
  MALAGASY = 'mg',
  MARSHALLESE = 'mh',
  MAORI = 'mi',
  MACEDONIAN = 'mk',
  MALAYALAM = 'ml',
  MONGOLIAN = 'mn',
  MARATHI = 'mr',
  MALAY = 'ms',
  MALTESE = 'mt',
  BURMESE = 'my',
  NAURU = 'na',
  BOKMÅL = 'nb',
  NDEBELE_NORTH = 'nd',
  NEPALI = 'ne',
  NDONGA = 'ng',
  DUTCH = 'nl',
  NORWEGIAN_NYNORSK = 'nn',
  NORWEGIAN = 'no',
  NDEBELE_SOUTH = 'nr',
  NAVAJO_NAVAHO = 'nv',
  CHICHEWA_CHEWA_NYANJA = 'ny',
  OCCITAN_POST_1500 = 'oc',
  OJIBWA = 'oj',
  OROMO = 'om',
  ORIYA = 'or',
  OSSETIAN_OSSETIC = 'os',
  PANJABI_PUNJABI = 'pa',
  PALI = 'pi',
  POLISH = 'pl',
  PUSHTO_PASHTO = 'ps',
  PORTUGUESE = 'pt',
  QUECHUA = 'qu',
  ROMANSH = 'rm',
  RUNDI = 'rn',
  ROMANIAN = 'ro',
  RUSSIAN = 'ru',
  KINYARWANDA = 'rw',
  SANSKRIT = 'sa',
  SARDINIAN = 'sc',
  SINDHI = 'sd',
  NORTHERN_SAMI = 'se',
  SANGO = 'sg',
  SINHALA = 'si',
  SLOVAK = 'sk',
  SLOVENIAN = 'sl',
  SAMOAN = 'sm',
  SHONA = 'sn',
  SOMALI = 'so',
  ALBANIAN = 'sq',
  SERBIAN = 'sr',
  SWATI = 'ss',
  SOTHO = 'st',
  SUNDANESE = 'su',
  SWEDISH = 'sv',
  SWAHILI = 'sw',
  TAMIL = 'ta',
  TELUGU = 'te',
  TAJIK = 'tg',
  THAI = 'th',
  TIGRINYA = 'ti',
  TURKMEN = 'tk',
  TAGALOG = 'tl',
  TSWANA = 'tn',
  TONGA = 'to',
  TURKISH = 'tr',
  TSONGA = 'ts',
  TATAR = 'tt',
  TWI = 'tw',
  TAHITIAN = 'ty',
  UIGHUR = 'ug',
  UKRAINIAN = 'uk',
  URDU = 'ur',
  UZBEK = 'uz',
  VENDA = 've',
  VIETNAMESE = 'vi',
  VOLAPÜK = 'vo',
  WALLOON = 'wa',
  WOLOF = 'wo',
  XHOSA = 'xh',
  YIDDISH = 'yi',
  YORUBA = 'yo',
  ZHUANG = 'za',
  CHINESE = 'zh',
  ZULU = 'zu',
}
