import { useState } from 'react';
import { WithChildrenProps } from 'src/utils/react';
import { createCtx } from '../../utils/context';
import { Uploader } from './uploader';
import { UploadedPart, UploadFileUrls } from './uploadFileModel';
import UploadingModal from './UploadingModal';

export interface UploaderContextType {
  upload: (
    title: string,
    blob: Blob,
    getUploadFileUrls: (parts: number) => Promise<UploadFileUrls>,
    onComplete: (urls: UploadFileUrls, parts: UploadedPart[]) => void,
  ) => void;
}

const [UploaderContext, CurrentUploaderContextProvider] =
  createCtx<UploaderContextType>();

export const UploaderProvider = ({ children }: WithChildrenProps) => {
  const [isUploading, setUploading] = useState(false);
  const [percent, setPercent] = useState(0);
  const [title, setTitle] = useState<string>('');

  const upload = async (
    title: string,
    blob: Blob,
    getUploadFileUrls: (parts: number) => Promise<UploadFileUrls>,
    onComplete: (urls: UploadFileUrls, parts: UploadedPart[]) => void,
  ) => {
    setPercent(0);
    setTitle(title);
    setUploading(true);
    const chunks = Math.ceil(blob.size / Uploader.CHUNK_SIZE);
    const urls = await getUploadFileUrls(chunks);
    const uploader = new Uploader(
      blob,
      urls,
      (progress) => setPercent(progress.percentage),
      async (parts: UploadedPart[]) => {
        await onComplete(urls, parts);
        setUploading(false);
        setPercent(0);
      },
      (e: Error) => {
        throw e;
      },
    );
    uploader.start();
  };

  return (
    <CurrentUploaderContextProvider value={{ upload }}>
      {children}
      {isUploading && <UploadingModal title={title} percent={percent} />}
    </CurrentUploaderContextProvider>
  );
};

export const useUploader = () => {
  return UploaderContext();
};
