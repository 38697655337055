import { Ability, Action, Subject } from '../ability';

class EditionAbility implements Ability {
  subject: Subject;
  action: Action;

  constructor(action: Action) {
    this.subject = 'Editions';
    this.action = action;
  }
}

export const EditionReadAbility = new EditionAbility('read');
export const EditionCreateAbility = new EditionAbility('create');
export const EditionUpdateAbility = new EditionAbility('update');
export const EditionUploadClipAbility = new EditionAbility(
  'uploadClip' as Action,
);

export const editionAbilities = [
  EditionReadAbility,
  EditionCreateAbility,
  EditionUploadClipAbility,
  EditionUpdateAbility,
];
export default editionAbilities;
