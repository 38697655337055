import axios, { AxiosInstance } from 'axios';
import { PostRoomRequest } from './model/postRoomRequest';
import { PutRoomRequest } from './model/putRoomRequest';
import { RoomResponse } from './model/roomResponse';

const API_URL = process.env.REACT_APP_API_URL;
const ROOMS_URL = `${API_URL}/rooms`;
const ROOM_BY_ID = (id: string) => `${ROOMS_URL}/${id}`;

export async function getRooms(
  axiosInstance: AxiosInstance = axios.create(),
): Promise<RoomResponse[]> {
  const response = await axiosInstance.get(ROOMS_URL);
  return response.data;
}

export async function getRoom(
  id: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<RoomResponse> {
  const response = await axiosInstance.get(ROOM_BY_ID(id));
  return response.data;
}

export async function postRoom(
  room: PostRoomRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<RoomResponse> {
  const response = await axiosInstance.post(ROOMS_URL, room);
  return response.data;
}

export async function putRoom(
  id: string,
  room: PutRoomRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<RoomResponse> {
  const response = await axiosInstance.put(ROOM_BY_ID(id), room);
  return response.data;
}

export async function deleteRoom(
  id: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<void> {
  await axiosInstance.delete(ROOM_BY_ID(id));
}
