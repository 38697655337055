import { Ability, Action, Subject } from '../ability';

class TimesheetsAbility implements Ability {
  subject: Subject;
  action: Action;

  constructor(action: Action) {
    this.subject = 'Timesheets';
    this.action = action;
  }
}

export const TimesheetReadAbility = new TimesheetsAbility('read');
export const TimesheetCreateAbility = new TimesheetsAbility('create');
export const TimesheetUpdateAbility = new TimesheetsAbility('update');
export const TimesheetDeleteAbility = new TimesheetsAbility('delete');

export const timesheetAbilities = [
  TimesheetReadAbility,
  TimesheetCreateAbility,
  TimesheetUpdateAbility,
  TimesheetDeleteAbility,
];
export default timesheetAbilities;
