import { Ability, Action, Subject } from '../ability';

class RetakeAbility implements Ability {
  subject: Subject;
  action: Action;

  constructor(action: Action) {
    this.subject = 'Retakes';
    this.action = action;
  }
}

export const RetakeReadAbility = new RetakeAbility('read');
export const RetakeReadRecordingAbility = new RetakeAbility(
  'readRecording' as Action,
);
export const RetakeReadEditingAbility = new RetakeAbility(
  'readEditing' as Action,
);
export const RetakeCreateAbility = new RetakeAbility('create');
export const RetakeListenAbility = new RetakeAbility('listen' as Action);
export const RetakeUploadClipAbility = new RetakeAbility(
  'uploadClip' as Action,
);
export const RetakeDownloadAAFAbility = new RetakeAbility(
  'downloadAAF' as Action,
);
export const RetakeDeleteAbility = new RetakeAbility('delete');
export const RetakeApproveRecordAbility = new RetakeAbility(
  'approveRecord' as Action,
);
export const RetakeApproveEditAbility = new RetakeAbility(
  'approveEdit' as Action,
);
export const RetakeRejectRecordAbility = new RetakeAbility(
  'rejectRecord' as Action,
);
export const RetakeRejectEditAbility = new RetakeAbility(
  'rejectEdit' as Action,
);
export const RetakeRetypeRecordAbility = new RetakeAbility(
  'retypeRecord' as Action,
);
export const RetakeRetypeEditAbility = new RetakeAbility(
  'retypeEdit' as Action,
);
export const FinishRecordRetakeAbility = new RetakeAbility(
  'finishRecord' as Action,
);
export const RetakeRecordCheckAbility = new RetakeAbility(
  'recordCheck' as Action,
);
export const RetakeEditionCheckAbility = new RetakeAbility(
  'editionCheck' as Action,
);

export const retakeAbilities = [
  RetakeReadAbility,
  RetakeReadRecordingAbility,
  RetakeReadEditingAbility,
  RetakeCreateAbility,
  RetakeListenAbility,
  RetakeUploadClipAbility,
  RetakeDownloadAAFAbility,
  RetakeDeleteAbility,
  RetakeApproveRecordAbility,
  RetakeApproveEditAbility,
  RetakeRejectRecordAbility,
  RetakeRejectEditAbility,
  RetakeRetypeRecordAbility,
  RetakeRetypeEditAbility,
  FinishRecordRetakeAbility,
  RetakeRecordCheckAbility,
  RetakeEditionCheckAbility,
];
export default retakeAbilities;
