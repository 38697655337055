import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

interface UploadingModalProps {
  title: string;
  percent: number;
}

const UploadingModal = (props: UploadingModalProps) => {
  const { title, percent } = props;

  return (
    <Dialog open>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box position="relative" display="inline-flex">
          <CircularProgress variant="determinate" value={percent} size={60} />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              variant="caption"
              component="div"
              color="textSecondary"
            >{`${percent}%`}</Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default UploadingModal;
