import { styled } from '@mui/material/styles';

const Logo = styled(
  (
    props: React.DetailedHTMLProps<
      React.ImgHTMLAttributes<HTMLImageElement>,
      HTMLImageElement
    >,
  ) => {
    return (
      <img
        alt="logo"
        src={
          props.datatype === 'text'
            ? '/static/images/logo-text.png'
            : '/static/images/logo.png'
        }
        {...props}
      />
    );
  },
)``;

export default Logo;
