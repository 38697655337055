import axios, { AxiosInstance } from 'axios';
import { PolicyResponse } from './model/policyResponse';
import { PostPolicyRequest } from './model/postPolicyRequest';
import { PutPolicyRequest } from './model/putPolicyRequest';

const API_URL = process.env.REACT_APP_API_URL;
const POLICIES_URL = `${API_URL}/iam/policies`;
const POLICY_BY_ID = (id: string) => `${POLICIES_URL}/${id}`;

export async function getPolicies(
  axiosInstance: AxiosInstance = axios.create(),
): Promise<PolicyResponse[]> {
  const response = await axiosInstance.get(POLICIES_URL);
  return response.data;
}

export async function getPolicy(
  id: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<PolicyResponse> {
  const response = await axiosInstance.get(POLICY_BY_ID(id));
  return response.data;
}

export async function postPolicy(
  policy: PostPolicyRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<PolicyResponse> {
  const response = await axiosInstance.post(POLICIES_URL, policy);
  return response.data;
}

export async function putPolicy(
  id: string,
  policy: PutPolicyRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<PolicyResponse> {
  const response = await axiosInstance.put(POLICY_BY_ID(id), policy);
  return response.data;
}

export async function deletePolicy(
  id: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<void> {
  await axiosInstance.delete(POLICY_BY_ID(id));
}
