import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';
import {
  Box,
  Drawer,
  alpha,
  styled,
  Divider,
  useTheme,
  lighten,
  darken,
} from '@mui/material';
import SidebarMenu from './SidebarMenu';
import Logo from 'src/components/Logo';
import HeaderProfilebox from '../Header/Profilebox';
import SidebarProjectMenu from './SidebarProjectMenu';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
`,
);

const Sidebar = () => {
  const { sidebarToggle, toggleSidebar, projectId } = SidebarContext();
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: 'none',
            lg: 'inline-block',
          },
          position: 'fixed',
          left: 0,
          top: theme.header.height,
          background:
            theme.palette.mode === 'dark'
              ? alpha(lighten(theme.header.background, 0.1), 0.5)
              : darken(theme.colors.alpha.black[100], 0.5),
          boxShadow:
            theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none',
          paddingBottom: `${theme.spacing(5)}`,
        }}
      >
        <Scrollbar>
          <Box mt="1rem" mx={1}>
            <HeaderProfilebox />
          </Box>
          <Divider
            sx={{
              mt: theme.spacing(2),
              mb: theme.spacing(2),
              mx: theme.spacing(2),
              background: theme.colors.alpha.trueWhite[10],
            }}
          />
          {projectId ? (
            <SidebarProjectMenu projectId={projectId} />
          ) : (
            <SidebarMenu />
          )}
        </Scrollbar>
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`,
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper
          sx={{
            background:
              theme.palette.mode === 'dark'
                ? theme.colors.alpha.white[100]
                : darken(theme.colors.alpha.black[100], 0.5),
          }}
        >
          <Scrollbar>
            <Box mt={1.5}>
              <Box
                mx={2}
                sx={{
                  width: 52,
                }}
              >
                <Logo height={40} />
              </Box>
            </Box>
            <Divider
              sx={{
                mt: theme.spacing(1),
                mx: theme.spacing(2),
                mb: theme.spacing(2),
                background: theme.colors.alpha.trueWhite[10],
              }}
            />
            {projectId ? (
              <SidebarProjectMenu projectId={projectId} />
            ) : (
              <SidebarMenu />
            )}
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
};

export default Sidebar;
