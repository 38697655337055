import { Alert, Backdrop, Grid, styled } from '@mui/material';
import loader from './loader.png';

const SpinnerImage = styled('img')(
  ({ theme }) => `
  animation: logo-spin 2s infinite linear;

  @keyframes logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`,
);

interface ARSLoaderProps {
  open?: boolean;
  text?: string;
}

const EARSLoader = ({ open = true, text }: ARSLoaderProps) => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh' }}
      >
        <Grid item xs={12}>
          <SpinnerImage src={loader} />
        </Grid>
        {text && (
          <Grid item xs={12}>
            <Alert variant="filled" severity="info" icon={false}>
              {text}
            </Alert>
          </Grid>
        )}
      </Grid>
    </Backdrop>
  );
};

export default EARSLoader;
