import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const HeaderLanguage = () => {
  const { t, i18n } = useTranslation(['header', 'common']);
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip arrow title={t('language_title')}>
        <Button color="primary" ref={ref} onClick={handleOpen}>
          {i18n.language.toUpperCase()}
        </Button>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{ p: 2 }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5">{t('language_title')}</Typography>
        </Box>
        <Divider />
        <List sx={{ p: 0 }}>
          <ListItem sx={{ p: 1, display: { xs: 'block', sm: 'flex' } }}>
            <ListItemButton
              onClick={() => {
                i18n.changeLanguage('en');
                handleClose();
              }}
            >
              <ListItemText primary={t('language_english')} />
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ p: 1, display: { xs: 'block', sm: 'flex' } }}>
            <ListItemButton
              onClick={() => {
                i18n.changeLanguage('es');
                handleClose();
              }}
            >
              <ListItemText primary={t('language_spanish')} />
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ p: 1, display: { xs: 'block', sm: 'flex' } }}>
            <ListItemButton
              onClick={() => {
                i18n.changeLanguage('br');
                handleClose();
              }}
            >
              <ListItemText primary={t('language_brazilian')} />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

export default HeaderLanguage;
