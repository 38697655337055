import { Box, Grid, Link, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import gitInfo from 'src/utils/git-info';

const FooterWrapper = styled(Box)(
  ({ theme }) => `
        position: fixed;
        bottom: 0px;
        left: 0px;
        padding: 10px 0 5px 0;
        background-color: #070C27;
        z-index: 1000;
        `,
);

const Footer = () => {
  const version = moment(gitInfo.date).format('YYYYMMDD') + '-' + gitInfo.hash;
  const { t } = useTranslation(['common']);
  const theme = useTheme();

  return (
    <FooterWrapper
      sx={{
        width: {
          xs: '100vw',
          lg: `calc(100vw - ${theme.sidebar.width})`,
        },
        marginLeft: {
          xs: '0px',
          lg: `calc(${theme.sidebar.width})`,
        },
      }}
    >
      <Grid container justifyContent="right">
        <Grid item xs={6}>
          <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
            &copy; {new Date().getFullYear()} - {t('companyName')} v.{version}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ textAlign: 'center' }} variant="subtitle1">
            <Link
              href="https://earscloud.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              earscloud.io
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </FooterWrapper>
  );
};

export default Footer;
