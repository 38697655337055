import axios, {
  AxiosError,
  AxiosRequestHeaders,
  InternalAxiosRequestConfig,
} from 'axios';
import { useSnackbar } from 'notistack';
import { createCtx } from 'src/utils/context';
import { WithChildrenProps } from 'src/utils/react';
import { useAuth } from '../AuthContext';
import {
  deletePolicy,
  getPolicies,
  getPolicy,
  postPolicy,
  putPolicy,
} from 'src/services/iam/policies/policies.api';
import {
  acceptInvite,
  deleteUser,
  generate2FA,
  getInvites,
  getUser,
  getNotificationPreferences,
  getUsers,
  getUsersByProfile,
  inviteUser,
  postUser,
  putUser,
  updateNotificationPreferences,
  validate2FA,
  uninviteUser,
} from 'src/services/iam/users/users.api';
import {
  deleteRoom,
  getRoom,
  getRooms,
  postRoom,
  putRoom,
} from 'src/services/rooms/rooms.api';
import { PostPolicyRequest } from 'src/services/iam/policies/model/postPolicyRequest';
import { getErrorMessage } from 'src/utils/error-handler';
import { PutPolicyRequest } from 'src/services/iam/policies/model/putPolicyRequest';
import { PutUserRequest } from 'src/services/iam/users/model/putUserRequest';
import { PostUserRequest } from 'src/services/iam/users/model/postUserRequest';
import { PutRoomRequest } from 'src/services/rooms/model/putRoomRequest';
import { PostRoomRequest } from 'src/services/rooms/model/postRoomRequest';
import { PostProjectRequest } from 'src/services/projects/model/postProjectRequest';
import {
  completeUploadProjectFile,
  deleteProjectFile,
  getProject,
  getProjectCalendar,
  getProjectContractInfo,
  getProjectFileUrl,
  getProjectFiles,
  getProjectSummary,
  getProjects,
  getUploadProjectFileUrls,
  postProject,
  putProject,
  updateProjecFileInfo,
} from 'src/services/projects/projects.api';
import { UploadedPart } from '../UploaderContext/uploadFileModel';
import { ProjectFileType } from '../../services/projects/model/projectEnums';
import {
  completeUploadRecordingFile,
  downloadRecordingAaf,
  downloadClips,
  getRecording,
  getRecordings,
  getUploadRecordingFileUrls,
  postRecording,
  downloadAaf,
  downloadRecordingsAaf,
  updateClips,
  uploadClip,
  completeUploadClip,
  copyClips,
  closeRecording,
  updateRecording,
  deleteRecording,
  downloadSomeClips,
  createRecordClip,
  uploadAudioToRecordClip,
  finishRecordClip,
} from '../../services/recordings/recordings.api';
import { PostRecordingRequest } from 'src/services/recordings/model/postRecordingRequest';
import { PostEditionRequest } from 'src/services/editions/model/postEditionRequest';
import {
  completeUploadEditionFile,
  getEdition,
  getEditions,
  getUploadEditionFileUrls,
  postEdition,
  putEdition,
} from 'src/services/editions/editions.api';
import {
  downloadReviewsAaf,
  getReviewByEdition,
  getReviews,
  downloadClips as downloadReviewClips,
  downloadSomeClips as downloadSomeReviewClips,
  sendRetakes,
  downloadAllReviewsAaf,
} from 'src/services/reviews/reviews.api';
import {
  completeUploadDeliveryFile,
  createDelivery,
  deliverProject,
  finishProject,
  downloadDeliveryAudio,
  getPublisherDeliveriesNewVersion,
  getDeliveries,
  getPublisherVersions,
  getUploadDeliveryFileUrls,
  updateDelivery,
  createPublisherReviews,
  getPublisherDeliveriesFinalVersion,
} from 'src/services/deliveries/deliveries.api';
import { Validate2FARequest } from 'src/services/iam/users/model/validate2FARequest';
import { PostProfileRequest } from 'src/services/iam/profiles/model/postProfileRequest';
import { PutProfileRequest } from 'src/services/iam/profiles/model/putProfileRequest';
import {
  getProfile,
  getProfiles,
  postProfile,
  putProfile,
} from 'src/services/iam/profiles/profiles.api';
import { APIContextType } from './types';
import {
  approve,
  completeUploadRetakeFile,
  deleteRetake,
  getRetake,
  getRetakes,
  getRetakesByEdition,
  getUploadRetakeFileUrls,
  postRetake,
  reject,
  uploadClip as uploadRetakeClip,
  completeUploadClip as completeUploadRetakeClip,
  updateClips as updateRetakeClips,
  copyClips as copyRetakeClips,
  recordCheck,
  editionCheck,
  finishRecord,
  retype,
  updateRetakesClips,
  copyRetakesClips,
  recordReject,
  createRecordClip as createRecordClipRetake,
  uploadAudioToRecordClip as uploadAudioToRecordClipRetake,
  finishRecordClip as finishRecordClipRetake,
} from 'src/services/retakes/retakes.api';
import { CreateRetakeRequest } from 'src/services/retakes/model/createRetakeRequest';
import {
  getStandards,
  getStandard,
  putStandard,
  putActiveStandard,
  putInactiveStandard,
  deleteStandard,
  postStandard,
} from 'src/services/standards/standards.api';
import { PutStandardRequest } from 'src/services/standards/model/putStandardRequest';
import { PostStandardRequest } from 'src/services/standards/model/postStandardRequest';
import {
  getSerie,
  getSeries,
  postSeries,
  putSeries,
} from 'src/services/series/series.api';
import { PostSeriesRequest } from 'src/services/series/model/postSeriesRequest';
import { UpdateClipRequest } from 'src/services/clips/model/updateClipRequest';
import { UploadClipRequest } from 'src/services/clips/model/uploadClipRequest';
import { useTranslation } from 'react-i18next';
import {
  deleteTimesheet,
  getTimesheet,
  getTimesheets,
  postTimesheet,
  putTimesheet,
} from 'src/services/timesheets/timesheets.api';
import { PostTimesheetRequest } from 'src/services/timesheets/model/postTimesheetRequest';
import { PutTimesheetRequest } from 'src/services/timesheets/model/putTimesheetRequest';
import {
  completeUploadAuxiliaryAudio,
  getAuxiliaryAudio,
  getAuxiliaryAudios,
  getUploadAuxiliaryAudioUrls,
} from 'src/services/auxiliaryAudios/auxiliaryAudios.api';
import { PostAuxiliaryAudioRequest } from 'src/services/auxiliaryAudios/model/postAuxiliaryAudioRequest';
import { PutFileRequest } from 'src/services/files/putFileRequest';
import {
  ProjectCalendarFilters,
  ProjectFilters,
} from 'src/services/projects/model/projectFilters';
import { RetakeType } from 'src/services/retakes/model/retakeEnums';
import { PutDeliveryRequest } from 'src/services/deliveries/model/putDeliveryRequest';
import {
  reportProgress,
  reportProject,
  reportUsers,
} from 'src/services/reports/reports.api';
import { CopyClipRequest } from 'src/services/clips/model/copyClipRequest';
import { CloseRecordingRequest } from 'src/services/recordings/model/closeRecordingRequest';
import { PostDeliveryRequest } from 'src/services/deliveries/model/postDeliveryRequest';
import { UpdateRetakeClipRequest } from 'src/services/retakes/model/updateRetakeClipRequest';
import { CopyRetakeClipRequest } from 'src/services/retakes/model/copyRetakeClipRequest';
import { UserFilters } from '../../services/iam/users/model/userFilters';
import {
  ProgressReportFilters,
  UsersReportFilters,
} from '../../services/reports/model/reportFilters';
import { InviteUserRequest } from 'src/services/iam/users/model/inviteUserRequest';
import { AuxiliaryAudioType } from '../../services/auxiliaryAudios/model/auxilaryAudioTypes';
import { UserNotificationPreferences as UserNotificationPreferences } from '../../services/iam/users/model/userNotificationPreferences';
import { PutSeriesRequest } from 'src/services/series/model/putSeriesRequest';
import { PutRecordingRequest } from 'src/services/recordings/model/updateRecordingRequest';
import { ReviewFilters } from '../../services/reviews/model/reviewFilters';
import { EditionFilters } from '../../services/editions/model/editionFilters';
import { DeliveryFilters } from '../../services/deliveries/model/deliveryFilters';
import {
  createSharedToken,
  getSharedAudioUrl,
} from 'src/services/sharedFiles/sharedFiles.api';
import {
  getNextClientCode,
  getClients,
  getClient,
  postClient,
  putClient,
  getUploadClientDocumentUrls,
  completeUploadClientDocument,
  getDocumentUrl,
  deleteClientDocument,
} from 'src/services/clients/clients.api';
import { PostClientRequest } from 'src/services/clients/model/postClientRequest';
import { PutClientRequest } from 'src/services/clients/model/putClientResponse';
import { ClientFilters } from 'src/services/clients/model/ClientFilters';
import { ClientDocumentType } from 'src/services/clients/model/clientsResponse';
import { RecordClipRequestDto } from 'src/services/clips/model/recordClipRequest';
import { FinishRecordClipRequest } from 'src/services/clips/model/finishRecordClipRequest';

const [APIContext, CurrentAPIContextProvider] = createCtx<APIContextType>();

export const APIProvider = ({ children }: WithChildrenProps) => {
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const axiosInstance = axios.create();
  const axiosInstanceIgnore404ErrorsInstance = axios.create();
  const axiosInstanceIgnore409ErrorsInstance = axios.create();
  const { t } = useTranslation(['common']);

  const bearerRequestInterceptor = (config: InternalAxiosRequestConfig) => {
    if (user && user.authToken) {
      config.headers = config.headers || ({} as AxiosRequestHeaders);
      config.headers.Authorization = `Bearer ${user.authToken}`;
    }

    if (user && user.profile) {
      config.headers = config.headers || ({} as AxiosRequestHeaders);
      config.headers.unit = user.profile.unit.id;
    }
    return config;
  };

  const errorsResponseInterceptors =
    (ignoreStatus?: number[]) => (error: any) => {
      let message;
      if (error instanceof AxiosError) {
        if (
          ignoreStatus &&
          error.response &&
          error.response.status &&
          ignoreStatus.includes(error.response?.status)
        ) {
          return;
        }

        if (error.response?.status === 401) {
          if (error.response?.data?.isTokenExpired) {
            message = t('error_sessionExpired');
            logout();
          }
        } else if (error.response?.data?.message) {
          message = error.response.data.message.toString();
        } else {
          message = error.message;
        }
      } else {
        message = getErrorMessage(error);
      }
      enqueueSnackbar(t(message), {
        variant: 'error',
        persist: true,
        preventDuplicate: true,
      });
      return Promise.reject();
    };

  axiosInstance.interceptors.request.use(bearerRequestInterceptor);
  axiosInstanceIgnore404ErrorsInstance.interceptors.request.use(
    bearerRequestInterceptor,
  );
  axiosInstanceIgnore409ErrorsInstance.interceptors.request.use(
    bearerRequestInterceptor,
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    errorsResponseInterceptors(),
  );
  axiosInstanceIgnore404ErrorsInstance.interceptors.response.use(
    (response) => response,
    errorsResponseInterceptors([404]),
  );
  axiosInstanceIgnore409ErrorsInstance.interceptors.response.use(
    (response) => response,
    errorsResponseInterceptors([409]),
  );

  const value = {
    projects: {
      getProjects: (filters?: ProjectFilters) =>
        getProjects(axiosInstance, filters),
      getProject: (id: string) => getProject(id, axiosInstance),
      getProjectSummary: (id: string) => getProjectSummary(id, axiosInstance),
      createProject: (project: PostProjectRequest) =>
        postProject(project, axiosInstance),
      updateProject: (id: string, project: PostProjectRequest) =>
        putProject(id, project, axiosInstance),
      getUploadProjectFileUrls: (
        id: string,
        type: ProjectFileType,
        filename: string,
        parts: number,
      ) => getUploadProjectFileUrls(id, type, filename, parts, axiosInstance),
      completeUploadProjectFile: (
        id: string,
        type: ProjectFileType,
        key: string,
        uploadId: string,
        parts: UploadedPart[],
      ) =>
        completeUploadProjectFile(
          id,
          type,
          key,
          uploadId,
          parts,
          axiosInstance,
        ),
      getProjectFileUrl: (
        id: string,
        type: ProjectFileType,
        fileId?: string,
        ignore404Errors?: boolean,
      ) =>
        getProjectFileUrl(
          id,
          type,
          fileId,
          ignore404Errors
            ? axiosInstanceIgnore404ErrorsInstance
            : axiosInstance,
        ),
      getProjectFiles: (projectId: string) =>
        getProjectFiles(projectId, axiosInstance),
      updateProjecFileInfo: (
        id: string,
        type: ProjectFileType,
        update: PutFileRequest,
      ) => updateProjecFileInfo(id, type, update, axiosInstance),
      deleteProjectFile: (
        projectId: string,
        type: ProjectFileType,
        fileId?: string,
      ) => deleteProjectFile(projectId, type, fileId, axiosInstance),
      getProjectCalendar: (
        projectId: string,
        filters: ProjectCalendarFilters,
      ) => getProjectCalendar(projectId, axiosInstance, filters),
      getProjectContractInfo: (projectId: string) =>
        getProjectContractInfo(projectId, axiosInstance),
    },
    recordings: {
      getRecordings: (projectId: string) =>
        getRecordings(projectId, axiosInstance),
      getRecording: (projectId: string, id: string) =>
        getRecording(projectId, id, axiosInstance),
      createRecording: (projectId: string, recording: PostRecordingRequest) =>
        postRecording(projectId, recording, axiosInstance),
      updateRecording: (
        projectId: string,
        recordingId: string,
        recording: PutRecordingRequest,
      ) => updateRecording(projectId, recordingId, recording, axiosInstance),
      getUploadRecordingFileUrls: (
        projectId: string,
        id: string,
        filename: string,
        parts: number,
      ) =>
        getUploadRecordingFileUrls(
          projectId,
          id,
          filename,
          parts,
          axiosInstance,
        ),
      completeUploadRecordingFile: (
        projectId: string,
        id: string,
        key: string,
        uploadId: string,
        parts: UploadedPart[],
      ) =>
        completeUploadRecordingFile(
          projectId,
          id,
          key,
          uploadId,
          parts,
          axiosInstance,
        ),
      downloadClips: (projectId: string, id: string) =>
        downloadClips(projectId, id, axiosInstance),
      downloadSomeClips: (projectId: string, id: string, clips: string[]) =>
        downloadSomeClips(projectId, id, clips),
      updateClips: (
        projectId: string,
        id: string,
        clips: UpdateClipRequest[],
      ) => updateClips(projectId, id, clips, axiosInstance),
      uploadClip: (projectId: string, id: string, request: UploadClipRequest) =>
        uploadClip(projectId, id, request, axiosInstance),
      recordClip: (
        projectId: string,
        id: string,
        request: RecordClipRequestDto,
      ) => createRecordClip(projectId, id, request, axiosInstance),
      finishRecordClip: (
        projectId: string,
        id: string,
        clipId: string,
        request: FinishRecordClipRequest,
      ) => finishRecordClip(projectId, id, clipId, request, axiosInstance),
      uploadAudioToRecordClip: (
        projectId: string,
        id: string,
        audioId: string,
        index: number,
        audio: ArrayBuffer,
      ) =>
        uploadAudioToRecordClip(
          projectId,
          id,
          audioId,
          index,
          audio,
          axiosInstance,
        ),
      copyClips: (projectId: string, id: string, request: CopyClipRequest[]) =>
        copyClips(projectId, id, request, axiosInstance),
      completeUploadClip: (
        projectId: string,
        id: string,
        key: string,
        uploadId: string,
        parts: UploadedPart[],
      ) =>
        completeUploadClip(projectId, id, key, uploadId, parts, axiosInstance),
      downloadAaf: (projectId: string) => downloadAaf(projectId, axiosInstance),
      downloadRecordingAaf: (projectId: string, id: string) =>
        downloadRecordingAaf(projectId, id, axiosInstance),
      downloadRecordingsAaf: (projectId: string, recordings: string[]) =>
        downloadRecordingsAaf(projectId, recordings, axiosInstance),
      closeRecording: (
        projectId: string,
        id: string,
        closeRecordingRequest: CloseRecordingRequest,
      ) => closeRecording(projectId, id, closeRecordingRequest, axiosInstance),
      deleteRecording: (projectId: string, id: string) =>
        deleteRecording(projectId, id, axiosInstance),
    },
    editions: {
      getEditions: (projectId: string, filters?: EditionFilters) =>
        getEditions(projectId, axiosInstance, filters),
      getEdition: (projectId: string, id: string) =>
        getEdition(projectId, id, axiosInstance),
      createEdition: (projectId: string, edition: PostEditionRequest) =>
        postEdition(projectId, edition, axiosInstance),
      updateEdition: (
        projectId: string,
        id: string,
        edition: PostEditionRequest,
      ) => putEdition(projectId, edition, id, axiosInstance),
      getUploadEditionFileUrls: (
        id: string,
        projectId: string,
        filename: string,
        parts: number,
        clipId?: string,
      ) =>
        getUploadEditionFileUrls(
          id,
          projectId,
          filename,
          parts,
          axiosInstance,
          clipId,
        ),
      completeUploadEditionFile: (
        id: string,
        projectId: string,
        key: string,
        uploadId: string,
        parts: UploadedPart[],
      ) =>
        completeUploadEditionFile(
          id,
          projectId,
          key,
          uploadId,
          parts,
          axiosInstance,
        ),
    },
    reviews: {
      getReviews: (projectId: string, filters?: ReviewFilters) =>
        getReviews(projectId, axiosInstance, filters),
      getReviewByEdition: (projectId: string, editionId: string) =>
        getReviewByEdition(projectId, editionId, axiosInstance),
      downloadClips: (projectId: string, id: string) =>
        downloadReviewClips(projectId, id, axiosInstance),
      downloadSomeClips: (projectId: string, id: string, clips: string[]) =>
        downloadSomeReviewClips(projectId, id, clips, axiosInstance),
      downloadAllReviewsAaf: (projectId: string) =>
        downloadAllReviewsAaf(projectId, axiosInstance),
      downloadReviewsAaf: (projectId: string, editions: string[]) =>
        downloadReviewsAaf(projectId, editions, axiosInstance),
      sendRetakes: (projectId: string, editionId: string) =>
        sendRetakes(projectId, editionId, axiosInstance),
    },
    retakes: {
      getRetakes: (projectId: string) => getRetakes(projectId, axiosInstance),
      getRetakesByEdition: (
        projectId: string,
        editionId: string,
        type?: RetakeType,
      ) => getRetakesByEdition(projectId, editionId, type, axiosInstance),
      getRetake: (projectId: string, retakeId: string) =>
        getRetake(projectId, retakeId, axiosInstance),
      postRetake: (projectId: string, retakeRequest: CreateRetakeRequest) =>
        postRetake(projectId, retakeRequest, axiosInstance),
      getUploadRetakeFileUrls: (
        projectId: string,
        id: string,
        filename: string,
        parts: number,
      ) =>
        getUploadRetakeFileUrls(projectId, id, filename, parts, axiosInstance),
      completeUploadRetakeFile: (
        projectId: string,
        id: string,
        key: string,
        uploadId: string,
        parts: UploadedPart[],
      ) =>
        completeUploadRetakeFile(
          projectId,
          id,
          key,
          uploadId,
          parts,
          axiosInstance,
        ),
      uploadClip: (
        projectId: string,
        editionId: string,
        request: UploadClipRequest,
      ) => uploadRetakeClip(projectId, editionId, request, axiosInstance),
      recordClip: (
        projectId: string,
        id: string,
        request: RecordClipRequestDto,
      ) => createRecordClipRetake(projectId, id, request, axiosInstance),
      finishRecordClip: (
        projectId: string,
        id: string,
        clipId: string,
        request: FinishRecordClipRequest,
      ) =>
        finishRecordClipRetake(projectId, id, clipId, request, axiosInstance),
      uploadAudioToRecordClip: (
        projectId: string,
        id: string,
        audioId: string,
        index: number,
        audio: ArrayBuffer,
      ) =>
        uploadAudioToRecordClipRetake(
          projectId,
          id,
          audioId,
          index,
          audio,
          axiosInstance,
        ),
      completeUploadClip: (
        projectId: string,
        editionId: string,
        key: string,
        uploadId: string,
        parts: UploadedPart[],
      ) =>
        completeUploadRetakeClip(
          projectId,
          editionId,
          key,
          uploadId,
          parts,
          axiosInstance,
        ),
      updateClips: (
        projectId: string,
        id: string,
        clips: UpdateClipRequest[],
      ) => updateRetakeClips(projectId, id, clips, axiosInstance),
      updateRetakesClips: (
        projectId: string,
        clips: UpdateRetakeClipRequest[],
      ) => updateRetakesClips(projectId, clips, axiosInstance),
      copyClips: (projectId: string, id: string, request: CopyClipRequest[]) =>
        copyRetakeClips(projectId, id, request, axiosInstance),
      copyRetakesClips: (projectId: string, request: CopyRetakeClipRequest[]) =>
        copyRetakesClips(projectId, request, axiosInstance),
      deleteRetake: (projectId: string, retakeId: string) =>
        deleteRetake(projectId, retakeId, axiosInstance),
      approve: (projectId: string, retakeId: string) =>
        approve(projectId, retakeId, axiosInstance),
      reject: (projectId: string, retakeId: string, description?: string) =>
        reject(projectId, retakeId, description, axiosInstance),
      retype: (projectId: string, retakeId: string, description?: string) =>
        retype(projectId, retakeId, description, axiosInstance),
      finishRecord: (projectId: string, retakeId: string) =>
        finishRecord(projectId, retakeId, axiosInstance),
      recordCheck: (projectId: string, retakeId: string) =>
        recordCheck(projectId, retakeId, axiosInstance),
      recordReject: (
        projectId: string,
        retakeId: string,
        description?: string,
      ) => recordReject(projectId, retakeId, description, axiosInstance),
      editionCheck: (projectId: string, retakeId: string) =>
        editionCheck(projectId, retakeId, axiosInstance),
    },
    deliveries: {
      createDelivery: (
        projectId: string,
        standardId: string,
        request: PostDeliveryRequest,
      ) => createDelivery(projectId, standardId, request, axiosInstance),
      getDeliveries: (
        projectId: string,
        standardId: string,
        filters?: DeliveryFilters,
      ) => getDeliveries(projectId, standardId, axiosInstance, filters),
      getUploadDeliveryFileUrls: (
        projectId: string,
        standardId: string,
        id: string,
        filename: string,
        parts: number,
      ) =>
        getUploadDeliveryFileUrls(
          projectId,
          standardId,
          id,
          filename,
          parts,
          axiosInstance,
        ),
      completeUploadDeliveryFile: (
        projectId: string,
        standardId: string,
        id: string,
        key: string,
        uploadId: string,
        parts: UploadedPart[],
      ) =>
        completeUploadDeliveryFile(
          projectId,
          standardId,
          id,
          key,
          uploadId,
          parts,
          axiosInstance,
        ),
      deliverProject: (projectId: string, standardId: string) =>
        deliverProject(projectId, standardId, axiosInstance),
      finishProject: (projectId: string, standardId: string) =>
        finishProject(projectId, standardId, axiosInstance),
      updateDelivery: (
        projectId: string,
        standardId: string,
        deliveryId: string,
        delivery: PutDeliveryRequest,
      ) =>
        updateDelivery(
          projectId,
          standardId,
          deliveryId,
          delivery,
          axiosInstance,
        ),
      getPublisherVersions: (projectId: string, standardId: string) =>
        getPublisherVersions(projectId, standardId, axiosInstance),
      downloadDeliveryAudio: (
        projectId: string,
        standardId: string,
        id: string,
      ) => downloadDeliveryAudio(projectId, standardId, id, axiosInstance),
      getPublisherDeliveriesNewVersion: (
        projectId: string,
        standardId: string,
      ) =>
        getPublisherDeliveriesNewVersion(projectId, standardId, axiosInstance),
      getPublisherDeliveriesFinalVersion: (
        projectId: string,
        standardId: string,
      ) =>
        getPublisherDeliveriesFinalVersion(
          projectId,
          standardId,
          axiosInstance,
        ),
      createPublisherReviews: (
        projectId: string,
        standardId: string,
        version: number,
      ) =>
        createPublisherReviews(projectId, standardId, version, axiosInstance),
    },
    rooms: {
      getRooms: () => getRooms(axiosInstance),
      getRoom: (id: string) => getRoom(id, axiosInstance),
      createRoom: (room: PostRoomRequest) => postRoom(room, axiosInstance),
      updateRoom: (id: string, room: PutRoomRequest) =>
        putRoom(id, room, axiosInstance),
      deleteRoom: (id: string) => deleteRoom(id, axiosInstance),
    },
    users: {
      getUsers: (filters?: UserFilters) => getUsers(axiosInstance, filters),
      getUser: (id: string) => getUser(id, axiosInstance),
      createUser: (user: PostUserRequest) =>
        postUser(user, axiosInstanceIgnore409ErrorsInstance),
      updateUser: (id: string, user: PutUserRequest) =>
        putUser(id, user, axiosInstance),
      deleteUser: (id: string) => deleteUser(id, axiosInstance),
      generate2FA: () => generate2FA(axiosInstance),
      validate2FA: (request: Validate2FARequest) =>
        validate2FA(request, axiosInstance),
      getUsersByProfile: (profileId: string) =>
        getUsersByProfile(profileId, axiosInstance),
      inviteUser: (request: InviteUserRequest) =>
        inviteUser(request, axiosInstance),
      uninviteUser: (id: string) => uninviteUser(id, axiosInstance),
      getInvites: () => getInvites(axiosInstance),
      acceptInvite: (token: string) => acceptInvite(token, axiosInstance),
      getNotificationPreferences: () =>
        getNotificationPreferences(axiosInstance),
      updateNotificationPreferences: (
        notificationPreferences: UserNotificationPreferences[],
      ) =>
        updateNotificationPreferences(notificationPreferences, axiosInstance),
    },
    policies: {
      getPolicies: () => getPolicies(axiosInstance),
      getPolicy: (id: string) => getPolicy(id, axiosInstance),
      createPolicy: (policy: PostPolicyRequest) =>
        postPolicy(policy, axiosInstance),
      updatePolicy: (id: string, policy: PutPolicyRequest) =>
        putPolicy(id, policy, axiosInstance),
      deletePolicy: (id: string) => deletePolicy(id, axiosInstance),
    },
    profiles: {
      getProfiles: () => getProfiles(axiosInstance),
      getProfile: (id: string) => getProfile(id, axiosInstance),
      createProfile: (user: PostProfileRequest) =>
        postProfile(user, axiosInstance),
      updateProfile: (id: string, user: PutProfileRequest) =>
        putProfile(id, user, axiosInstance),
    },
    standards: {
      getStandards: () => getStandards(axiosInstance),
      getStandard: (id: string) => getStandard(id, axiosInstance),
      createStandard: (standard: PostStandardRequest) =>
        postStandard(standard, axiosInstance),
      updateStandard: (id: string, standard: PutStandardRequest) =>
        putStandard(id, standard, axiosInstance),
      setActiveStandard: (id: string) => putActiveStandard(id, axiosInstance),
      setInactiveStandard: (id: string) =>
        putInactiveStandard(id, axiosInstance),
      deleteStandard: (id: string) => deleteStandard(id, axiosInstance),
    },
    series: {
      getSeries: () => getSeries(axiosInstance),
      getSerie: (id: string) => getSerie(id, axiosInstance),
      createSerie: (serie: PostSeriesRequest) =>
        postSeries(serie, axiosInstance),
      updateSerie: (id: string, serie: PutSeriesRequest) =>
        putSeries(id, serie, axiosInstance),
    },
    timesheets: {
      getTimesheets: (projectId: string) =>
        getTimesheets(projectId, axiosInstance),
      getTimesheet: (id: string, projectId: string) =>
        getTimesheet(id, projectId, axiosInstance),
      createTimesheet: (projectId: string, timesheet: PostTimesheetRequest) =>
        postTimesheet(timesheet, projectId, axiosInstance),
      updateTimesheet: (
        id: string,
        projectId: string,
        timesheet: PutTimesheetRequest,
      ) => putTimesheet(id, projectId, timesheet, axiosInstance),
      deleteTimesheet: (id: string, projectId: string) =>
        deleteTimesheet(id, projectId, axiosInstance),
    },
    auxiliaryAudios: {
      getAuxiliaryAudios: (projectId: string, type: AuxiliaryAudioType) =>
        getAuxiliaryAudios(projectId, type, axiosInstance),
      getAuxiliaryAudio: (
        projectId: string,
        id: string,
        type: AuxiliaryAudioType,
      ) => getAuxiliaryAudio(projectId, id, type, axiosInstance),
      getUploadAuxiliaryAudioUrls: (
        projectId: string,
        type: AuxiliaryAudioType,
        filename: string,
        parts: number,
      ) =>
        getUploadAuxiliaryAudioUrls(
          projectId,
          type,
          filename,
          parts,
          axiosInstance,
        ),
      completeUploadAuxiliaryAudio: (
        projectId: string,
        type: AuxiliaryAudioType,
        auxiliaryAudio: PostAuxiliaryAudioRequest,
      ) =>
        completeUploadAuxiliaryAudio(
          projectId,
          type,
          auxiliaryAudio,
          axiosInstance,
        ),
    },
    reports: {
      reportProject: (projectId: string) =>
        reportProject(projectId, axiosInstance),
      reportUsers: (filters?: UsersReportFilters) =>
        reportUsers(axiosInstance, filters),
      reportProgress: (filters?: ProgressReportFilters) =>
        reportProgress(axiosInstance, filters),
    },
    sharedFiles: {
      createSharedToken: (projectId: string, audioId: string) =>
        createSharedToken(projectId, audioId, axiosInstance),
      getSharedAudioUrl: (shareToken: string) =>
        getSharedAudioUrl(shareToken, axiosInstance),
    },
    clients: {
      getNextClientCode: () => getNextClientCode(axiosInstance),
      getClients: (filters?: ClientFilters) =>
        getClients(axiosInstance, filters),
      getClient: (id: string) => getClient(id, axiosInstance),
      createClient: (client: PostClientRequest) =>
        postClient(client, axiosInstance),
      updateClient: (id: string, client: PutClientRequest) =>
        putClient(id, client, axiosInstance),
      getUploadClientDocumentUrls: (
        id: string,
        type: ClientDocumentType,
        filename: string,
        parts: number,
      ) =>
        getUploadClientDocumentUrls(id, type, filename, parts, axiosInstance),
      completeUploadClientDocument: (
        id: string,
        type: ClientDocumentType,
        name: string,
        key: string,
        uploadId: string,
        parts: UploadedPart[],
      ) =>
        completeUploadClientDocument(
          id,
          type,
          name,
          key,
          uploadId,
          parts,
          axiosInstance,
        ),
      getDocumentUrl: (
        id: string,
        type: ClientDocumentType,
        ignore404Errors?: boolean,
      ) =>
        getDocumentUrl(
          id,
          type,
          ignore404Errors
            ? axiosInstanceIgnore404ErrorsInstance
            : axiosInstance,
        ),
      deleteClientDocument: (id: string, type: ClientDocumentType) =>
        deleteClientDocument(id, type, axiosInstance),
    },
  };
  return (
    <CurrentAPIContextProvider value={value}>
      {children}
    </CurrentAPIContextProvider>
  );
};

export const useAPI = () => {
  return APIContext();
};
