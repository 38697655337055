import { Ability, Action, Subject } from '../ability';

class RecordingAbility implements Ability {
  subject: Subject;
  action: Action;

  constructor(action: Action) {
    this.subject = 'Recordings';
    this.action = action;
  }
}

export const RecordingReadAbility = new RecordingAbility('read');
export const RecordingCreateAbility = new RecordingAbility('create');
export const RecordingDeleteAbility = new RecordingAbility('delete');
export const RecordingDownloadAAFAbility = new RecordingAbility(
  'downloadAAF' as Action,
);
export const RecordingViewClipAbility = new RecordingAbility(
  'viewClip' as Action,
);
export const RecordingUploadClipAbility = new RecordingAbility(
  'uploadClip' as Action,
);
export const RecordingRecordClipAbility = new RecordingAbility(
  'recordClip' as Action,
);
export const RecordingEditClipAbility = new RecordingAbility(
  'editClip' as Action,
);
export const RecordingCloseSessionAbility = new RecordingAbility(
  'closeSession' as Action,
);

export const recordingAbilities = [
  RecordingReadAbility,
  RecordingCreateAbility,
  RecordingDeleteAbility,
  RecordingDownloadAAFAbility,
  RecordingViewClipAbility,
  RecordingUploadClipAbility,
  RecordingRecordClipAbility,
  RecordingEditClipAbility,
  RecordingCloseSessionAbility,
];
export default recordingAbilities;
