import axios, { AxiosInstance } from 'axios';
import { PostClientRequest } from './model/postClientRequest';
import {
  ClientsDocument,
  ClientDocumentType,
  ClientsResponse,
} from './model/clientsResponse';
import { PutClientRequest } from './model/putClientResponse';
import { ClientFilters } from './model/ClientFilters';
import {
  UploadFileUrls,
  UploadedPart,
} from 'src/contexts/UploaderContext/uploadFileModel';
import { FileUrlResponse } from '../files/fileUrlResponse';

const API_URL = process.env.REACT_APP_API_URL;
const CLIENTS_URL = `${API_URL}/clients`;
const UPLOAD_FILE_URL = `/uploadFile`;
const NEXT_CLIENT_CODE_URL = `${CLIENTS_URL}/next-client-code`;
const CLIENT_BY_ID = (id: string, rest = '') => `${CLIENTS_URL}/${id}${rest}`;
const CLIENT_DOCUMENT = (id: string, type: ClientDocumentType) =>
  `${CLIENT_BY_ID(id)}/document/${type}`;

export async function getNextClientCode(
  axiosInstance: AxiosInstance = axios.create(),
): Promise<number> {
  const response = await axiosInstance.get(NEXT_CLIENT_CODE_URL);
  return response.data;
}

export async function getClients(
  axiosInstance: AxiosInstance = axios.create(),
  filters?: ClientFilters,
): Promise<ClientsResponse[]> {
  const response = await axiosInstance.get(CLIENTS_URL, { params: filters });
  return response.data;
}

export async function getClient(
  id: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<ClientsResponse> {
  const response = await axiosInstance.get(CLIENT_BY_ID(id));
  return response.data;
}

export async function postClient(
  client: PostClientRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<ClientsResponse> {
  const response = await axiosInstance.post(CLIENTS_URL, client);
  return response.data;
}

export async function putClient(
  id: string,
  client: PutClientRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<ClientsResponse> {
  const response = await axiosInstance.put(CLIENT_BY_ID(id), client);
  return response.data;
}

export async function getUploadClientDocumentUrls(
  id: string,
  type: ClientDocumentType,
  filename: string,
  parts: number,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<UploadFileUrls> {
  const response = await axiosInstance.post(CLIENT_BY_ID(id, UPLOAD_FILE_URL), {
    name: filename,
    type,
    parts,
  });
  return response.data;
}

export async function completeUploadClientDocument(
  id: string,
  type: ClientDocumentType,
  name: string,
  key: string,
  uploadId: string,
  parts: UploadedPart[],
  axiosInstance: AxiosInstance = axios.create(),
): Promise<ClientsDocument> {
  const response = await axiosInstance.put(CLIENT_BY_ID(id, UPLOAD_FILE_URL), {
    key,
    type,
    name,
    uploadId,
    parts,
  });
  return response.data;
}

export async function getDocumentUrl(
  id: string,
  type: ClientDocumentType,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<FileUrlResponse> {
  const response = await axiosInstance.get(CLIENT_DOCUMENT(id, type));
  return response.data;
}

export async function deleteClientDocument(
  id: string,
  type: ClientDocumentType,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<void> {
  await axiosInstance.delete(CLIENT_DOCUMENT(id, type));
}
