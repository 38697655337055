import { Ability, Action, Subject } from '../ability';

class PublisherReviewAbility implements Ability {
  subject: Subject;
  action: Action;

  constructor(action: Action) {
    this.subject = 'PublisherReview';
    this.action = action;
  }
}

export const PublisherReviewReadAbility = new PublisherReviewAbility('read');
export const PublisherReviewListenAbility = new PublisherReviewAbility(
  'listen' as Action,
);
export const PublisherReviewFinalizeAbility = new PublisherReviewAbility(
  'finalize' as Action,
);
export const PublisherReviewSendRetakesAbility = new PublisherReviewAbility(
  'sendRetakes' as Action,
);
export const PublisherReviewUploadClipAbility = new PublisherReviewAbility(
  'uploadClip' as Action,
);

export const PublisherReviewDownloadAAFAbility = new PublisherReviewAbility(
  'downloadAAF' as Action,
);

export const publisherReviewAbilities = [
  PublisherReviewReadAbility,
  PublisherReviewListenAbility,
  PublisherReviewFinalizeAbility,
  PublisherReviewSendRetakesAbility,
  PublisherReviewUploadClipAbility,
  PublisherReviewDownloadAAFAbility,
];
export default publisherReviewAbilities;
