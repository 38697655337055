import { Suspense, lazy, FC } from 'react';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import { WithChildrenProps } from './utils/react';
import EARSLoader from './contexts/LoaderContext/EARSLoader';

const Loader =
  (Component: FC<WithChildrenProps>) => (props: WithChildrenProps) =>
    (
      <Suspense fallback={<EARSLoader />}>
        <Component {...props} />
      </Suspense>
    );

const Status404 = Loader(lazy(() => import('src/pages/Status/Status404')));

const Auth = Loader(lazy(() => import('src/components/Auth')));
const Login = Loader(lazy(() => import('src/pages/Login')));
const Logout = Loader(lazy(() => import('src/pages/Logout')));

const Projects = Loader(lazy(() => import('src/pages/Audiobooks/Projects')));
const CreateProjectForm = Loader(
  lazy(() => import('src/pages/Audiobooks/Projects/CreateProjectForm')),
);
const ViewProjectForm = Loader(
  lazy(() => import('src/pages/Audiobooks/Projects/ViewProjectForm')),
);
const RecordingStudio = Loader(
  lazy(() => import('src/pages/Audiobooks/Studio/RecordingStudio')),
);
const ReviewStudio = Loader(
  lazy(() => import('src/pages/Audiobooks/Studio/ReviewStudio')),
);

// const Rooms = Loader(lazy(() => import('src/pages/Audiobooks/Rooms')));
// const RoomsForm = Loader(lazy(() => import('src/pages/Audiobooks/Rooms/Form')));

const Users = Loader(lazy(() => import('src/pages/Security/Users')));
const UsersForm = Loader(lazy(() => import('src/pages/Security/Users/Form')));
const InviteUserForm = Loader(
  lazy(() => import('src/pages/Security/Users/Invite')),
);

const Policies = Loader(lazy(() => import('src/pages/Security/Policies')));
const PoliciesForm = Loader(
  lazy(() => import('src/pages/Security/Policies/Form')),
);

const Profiles = Loader(lazy(() => import('src/pages/Security/Profiles')));
const ProfilesForm = Loader(
  lazy(() => import('src/pages/Security/Profiles/Form')),
);

const Settings = Loader(lazy(() => import('src/pages/Account/Settings')));
const Invitations = Loader(lazy(() => import('src/pages/Account/Invitations')));

const Standards = Loader(lazy(() => import('src/pages/Audiobooks/Standards')));
const CreateStandardForm = Loader(
  lazy(() => import('src/pages/Audiobooks/Standards/CreateStandardForm')),
);

const Series = Loader(lazy(() => import('src/pages/Audiobooks/Series')));
const CreateSerieForm = Loader(
  lazy(() => import('src/pages/Audiobooks/Series/CreateSerieForm')),
);

const Reports = Loader(lazy(() => import('src/pages/Reports')));

const RecoverPassword = Loader(
  lazy(() => import('./pages/Login/RecoverPassword')),
);

const ResetPassword = Loader(lazy(() => import('./pages/Login/ResetPassword')));

const Share = Loader(lazy(() => import('src/pages/Shared')));

const Clients = Loader(lazy(() => import('src/pages/Audiobooks/Clients')));
const ClientsForm = Loader(
  lazy(() => import('src/pages/Audiobooks/Clients/Form')),
);

const routes: RouteObject[] = [
  {
    element: <BaseLayout footer={false} />,
    path: '/projects/:projectId/recording',
    children: [
      {
        path: ':recordingId',
        element: <RecordingStudio />,
      },
    ],
  },
  {
    element: <BaseLayout footer={false} />,
    path: '/projects/:projectId/review',
    children: [
      {
        path: ':editionId',
        element: <ReviewStudio />,
      },
    ],
  },
  {
    element: <BaseLayout />,
    children: [
      {
        path: '/login',
        children: [
          {
            path: '',
            element: <Login />,
          },
          {
            path: 'recover-password',
            element: <RecoverPassword />,
          },
          {
            path: 'reset-password/:token',
            element: <ResetPassword />,
          },
        ],
      },
      {
        path: '/logout',
        element: <Logout />,
      },
      {
        element: <Auth />,
        children: [
          {
            path: '/',
            element: <SidebarLayout />,
            children: [
              {
                path: 'projects',
                children: [
                  {
                    path: '',
                    element: <Projects />,
                  },
                  {
                    path: 'new',
                    element: <CreateProjectForm />,
                  },
                  {
                    path: ':id',
                    element: <ViewProjectForm />,
                  },
                  {
                    path: ':id/:tab',
                    element: <ViewProjectForm />,
                  },
                  {
                    path: ':id/edit',
                    element: <CreateProjectForm />,
                  },
                ],
              },
              // {
              //   path: 'rooms',
              //   children: [
              //     {
              //       path: '',
              //       element: <Rooms />,
              //     },
              //     {
              //       path: ':id',
              //       element: <RoomsForm />,
              //     },
              //   ],
              // },
              {
                path: 'security',
                children: [
                  {
                    path: 'users',
                    children: [
                      {
                        path: '',
                        element: <Users />,
                      },
                      {
                        path: 'invite',
                        element: <InviteUserForm />,
                      },
                      {
                        path: ':id',
                        element: <UsersForm />,
                      },
                    ],
                  },
                  {
                    path: 'policies',
                    children: [
                      {
                        path: '',
                        element: <Policies />,
                      },
                      {
                        path: ':id',
                        element: <PoliciesForm />,
                      },
                    ],
                  },
                  {
                    path: 'profiles',
                    children: [
                      {
                        path: '',
                        element: <Profiles />,
                      },
                      {
                        path: ':id',
                        element: <ProfilesForm />,
                      },
                    ],
                  },
                ],
              },
              {
                path: 'account',
                children: [
                  {
                    path: 'settings',
                    element: <Settings />,
                  },
                  {
                    path: 'invitations',
                    element: <Invitations />,
                  },
                ],
              },
              {
                path: 'standards',
                children: [
                  {
                    path: '',
                    element: <Standards />,
                  },
                  {
                    path: ':id',
                    element: <CreateStandardForm />,
                  },
                ],
              },
              {
                path: 'series',
                children: [
                  {
                    path: '',
                    element: <Series />,
                  },
                  {
                    path: 'new',
                    element: <CreateSerieForm />,
                  },
                  {
                    path: ':id',
                    element: <CreateSerieForm />,
                  },
                ],
              },
              {
                path: 'reports',
                children: [
                  {
                    path: '',
                    element: <Reports />,
                  },
                ],
              },
              {
                path: 'clients',
                children: [
                  {
                    path: '',
                    element: <Clients />,
                  },
                  {
                    path: ':id',
                    element: <ClientsForm />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: '/shared/audio/:token',
        element: <Share />,
      },
      {
        path: '*',
        element: <Status404 />,
      },
    ],
  },
];

export default routes;
