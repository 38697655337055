import axios, { AxiosInstance } from 'axios';
import { ReportResponse } from './model/reportResponse';
import { UsersReportResponse } from './model/reportUsersResponse';
import {
  ProgressReportFilters,
  UsersReportFilters as UsersReportFilters,
} from './model/reportFilters';
import { ProgressReportResponse } from './model/reportProgressResponse';

const API_URL = process.env.REACT_APP_API_URL;
const REPORTS_URL = `${API_URL}/reports`;
const REPORT_PROJECT_BY_ID = (id: string, rest = '') =>
  `${REPORTS_URL}/project/${id}${rest}`;

export async function reportProject(
  projectId: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<ReportResponse> {
  const response = await axiosInstance.get(REPORT_PROJECT_BY_ID(projectId));
  return response.data;
}

export async function reportUsers(
  axiosInstance: AxiosInstance = axios.create(),
  filters?: UsersReportFilters,
): Promise<UsersReportResponse[]> {
  const response = await axiosInstance.get(`${REPORTS_URL}/users`, {
    params: filters,
  });
  return response.data;
}

export async function reportProgress(
  axiosInstance: AxiosInstance = axios.create(),
  filters?: ProgressReportFilters,
): Promise<ProgressReportResponse[]> {
  const response = await axiosInstance.get(`${REPORTS_URL}/progress`, {
    params: filters,
  });
  return response.data;
}
