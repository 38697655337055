export interface GitInformation {
  date: Date;
  hash: string;
}

const gitInfo: GitInformation = {
  date: process.env.REACT_APP_BUILD_TIME
    ? new Date(parseInt(process.env.REACT_APP_BUILD_TIME) || '')
    : new Date(0),
  hash: process.env.REACT_APP_COMMIT_HASH || 'XXX',
};

export default gitInfo;
