import axios, { AxiosInstance } from 'axios';
import { PostTimesheetRequest } from './model/postTimesheetRequest';
import { PutTimesheetRequest } from './model/putTimesheetRequest';
import { TimesheetResponse } from './model/timesheetResponse';

const API_URL = process.env.REACT_APP_API_URL;
const TIMESHEETS_URL = (projectId: string) =>
  `${API_URL}/projects/${projectId}/timesheets`;

const TIMESHEETS_BY_URL = (projectId: string, id: string) =>
  `${TIMESHEETS_URL(projectId)}/${id}`;

export async function getTimesheets(
  projectId: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<TimesheetResponse[]> {
  const response = await axiosInstance.get(TIMESHEETS_URL(projectId));
  return response.data;
}

export async function getTimesheet(
  id: string,
  projectId: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<TimesheetResponse> {
  const response = await axiosInstance.get(TIMESHEETS_BY_URL(projectId, id));
  return response.data;
}

export async function postTimesheet(
  timesheet: PostTimesheetRequest,
  projectId: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<TimesheetResponse> {
  const response = await axiosInstance.post(
    TIMESHEETS_URL(projectId),
    timesheet,
  );
  return response.data;
}

export async function putTimesheet(
  id: string,
  projectId: string,
  timesheet: PutTimesheetRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<TimesheetResponse> {
  const response = await axiosInstance.put(
    TIMESHEETS_BY_URL(projectId, id),
    timesheet,
  );
  return response.data;
}

export async function deleteTimesheet(
  id: string,
  projectId: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<void> {
  await axiosInstance.delete(TIMESHEETS_BY_URL(projectId, id));
}
