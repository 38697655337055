import axios, { AxiosInstance } from 'axios';
import { RetakeResponse } from './model/retakeResponse';
import { CreateRetakeRequest } from './model/createRetakeRequest';
import {
  UploadFileUrls,
  UploadedPart,
} from 'src/contexts/UploaderContext/uploadFileModel';
import { UploadClipRequest } from '../clips/model/uploadClipRequest';
import { UploadClipResponse } from '../clips/model/uploadClipResponse';
import { RetakeType } from './model/retakeEnums';
import { CopyClipRequest } from '../clips/model/copyClipRequest';
import { CopyClipResponse } from '../clips/model/copyClipResponse';
import { UpdateClipRequest } from '../clips/model/updateClipRequest';
import { APIResponse } from '../api';
import { UpdateRetakeClipRequest } from './model/updateRetakeClipRequest';
import { CopyRetakeClipRequest } from './model/copyRetakeClipRequest';
import { FinishRecordClipRequest } from '../clips/model/finishRecordClipRequest';
import { RecordClipResponse } from '../clips/model/recordClipResponse';
import { RecordClipRequestDto } from '../clips/model/recordClipRequest';
import { ClipResponseDto } from '../clips/model/clipResponse';

const API_URL = process.env.REACT_APP_API_URL;
const RETAKES_URL = (projectId: string, rest = '') =>
  `${API_URL}/projects/${projectId}/retakes${rest}`;
const RETAKE_BY_ID = (projectId: string, retakeId: string, rest = '') =>
  `${RETAKES_URL(projectId)}/${retakeId}${rest}`;
const RETAKES_BY_EDITION = (projectId: string, editionId: string, rest = '') =>
  `${RETAKES_URL(projectId)}/edition/${editionId}${rest}`;
const UPLOAD_RECORDING_URL = `/upload`;
const APPROVE_URL = `/approve`;
const REJECT_URL = `/reject`;
const RETYPE_URL = `/retype`;
const FINISH_RECORD_URL = `/finishRecord`;
const RECORD_CHECK_URL = `/recordCheck`;
const RECORD_REJECT_URL = `/recordReject`;
const EDITION_CHECK_URL = `/editionCheck`;
const CLIPS_URL = `/clips`;
const CLIPS_COPY_URL = `${CLIPS_URL}/copy`;
const CLIPS_UPLOAD_URL = `${CLIPS_URL}/upload`;
const CLIPS_RECORD_URL = `${CLIPS_URL}/record`;
const CLIPS_FINISH_RECORD_URL = (clipId: string) =>
  `${CLIPS_URL}/${clipId}/finish-record`;
const CLIPS_RECORD_AUDIO_UPLOAD_URL = (audioId: string) =>
  `${CLIPS_URL}/${audioId}/upload`;

export async function getRetakes(
  projectId: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<RetakeResponse[]> {
  const response = await axiosInstance.get(RETAKES_URL(projectId));
  return response.data;
}

export async function getRetakesByEdition(
  projectId: string,
  editionId: string,
  type?: RetakeType,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<RetakeResponse[]> {
  const response = await axiosInstance.get(
    RETAKES_BY_EDITION(projectId, editionId),
    { params: { type } },
  );
  return response.data;
}

export async function getRetake(
  projectId: string,
  retakeId: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<RetakeResponse> {
  const response = await axiosInstance.get(RETAKE_BY_ID(projectId, retakeId));
  return response.data;
}

export async function postRetake(
  projectId: string,
  retakeRequest: CreateRetakeRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<RetakeResponse> {
  const response = await axiosInstance.post(
    RETAKES_URL(projectId),
    retakeRequest,
  );
  return response.data;
}

export async function getUploadRetakeFileUrls(
  projectId: string,
  id: string,
  filename: string,
  parts: number,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<UploadFileUrls> {
  const response = await axiosInstance.post(
    RETAKE_BY_ID(projectId, id, UPLOAD_RECORDING_URL),
    {
      name: filename,
      parts,
    },
  );
  return response.data;
}

export async function completeUploadRetakeFile(
  projectId: string,
  id: string,
  key: string,
  uploadId: string,
  parts: UploadedPart[],
  axiosInstance: AxiosInstance = axios.create(),
): Promise<RetakeResponse> {
  const response = await axiosInstance.put(
    RETAKE_BY_ID(projectId, id, UPLOAD_RECORDING_URL),
    {
      key,
      uploadId,
      parts,
    },
  );
  return response.data;
}

export async function uploadClip(
  projectId: string,
  editionId: string,
  request: UploadClipRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<UploadClipResponse> {
  const response = await axiosInstance.post(
    RETAKE_BY_ID(projectId, editionId, CLIPS_UPLOAD_URL),
    request,
  );
  return response.data;
}

export async function createRecordClip(
  projectId: string,
  id: string,
  request: RecordClipRequestDto,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<RecordClipResponse> {
  const response = await axiosInstance.post(
    RETAKE_BY_ID(projectId, id, CLIPS_RECORD_URL),
    request,
  );
  return response.data;
}

export async function uploadAudioToRecordClip(
  projectId: string,
  id: string,
  audioId: string,
  index: number,
  audio: ArrayBuffer,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<void> {
  const formData = new FormData();
  formData.append('audio', new Blob([audio]));
  await axiosInstance.post(
    RETAKE_BY_ID(projectId, id, CLIPS_RECORD_AUDIO_UPLOAD_URL(audioId)),
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: { index },
    },
  );
}

export async function finishRecordClip(
  projectId: string,
  id: string,
  clipId: string,
  request: FinishRecordClipRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<ClipResponseDto> {
  const response = await axiosInstance.post(
    RETAKE_BY_ID(projectId, id, CLIPS_FINISH_RECORD_URL(clipId)),
    request,
  );
  return response.data;
}

export async function completeUploadClip(
  projectId: string,
  editionId: string,
  key: string,
  uploadId: string,
  parts: UploadedPart[],
  axiosInstance: AxiosInstance = axios.create(),
): Promise<APIResponse> {
  const response = await axiosInstance.put(
    RETAKE_BY_ID(projectId, editionId, CLIPS_UPLOAD_URL),
    {
      key,
      uploadId,
      parts,
    },
  );
  return response.data;
}

export async function updateClips(
  projectId: string,
  id: string,
  clips: UpdateClipRequest[],
  axiosInstance: AxiosInstance = axios.create(),
): Promise<void> {
  const response = await axiosInstance.put(
    RETAKE_BY_ID(projectId, id, CLIPS_URL),
    clips,
  );
  return response.data;
}

export async function updateRetakesClips(
  projectId: string,
  clips: UpdateRetakeClipRequest[],
  axiosInstance: AxiosInstance = axios.create(),
): Promise<void> {
  const response = await axiosInstance.put(
    RETAKES_URL(projectId, CLIPS_URL),
    clips,
  );
  return response.data;
}

export async function copyClips(
  projectId: string,
  id: string,
  request: CopyClipRequest[],
  axiosInstance: AxiosInstance = axios.create(),
): Promise<CopyClipResponse[]> {
  const response = await axiosInstance.post(
    RETAKE_BY_ID(projectId, id, CLIPS_COPY_URL),
    request,
  );
  return response.data;
}

export async function copyRetakesClips(
  projectId: string,
  request: CopyRetakeClipRequest[],
  axiosInstance: AxiosInstance = axios.create(),
): Promise<CopyClipResponse[]> {
  const response = await axiosInstance.post(
    RETAKES_URL(projectId, CLIPS_COPY_URL),
    request,
  );
  return response.data;
}

export async function deleteRetake(
  projectId: string,
  retakeId: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<void> {
  await axiosInstance.delete(RETAKE_BY_ID(projectId, retakeId));
}

export async function approve(
  projectId: string,
  retakeId: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<RetakeResponse> {
  const response = await axiosInstance.post(
    RETAKE_BY_ID(projectId, retakeId, APPROVE_URL),
  );
  return response.data;
}

export async function reject(
  projectId: string,
  retakeId: string,
  description?: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<RetakeResponse> {
  const response = await axiosInstance.post(
    RETAKE_BY_ID(projectId, retakeId, REJECT_URL),
    description ? { description } : undefined,
  );
  return response.data;
}

export async function retype(
  projectId: string,
  retakeId: string,
  description?: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<RetakeResponse> {
  const response = await axiosInstance.post(
    RETAKE_BY_ID(projectId, retakeId, RETYPE_URL),
    description ? { description } : undefined,
  );
  return response.data;
}

export async function finishRecord(
  projectId: string,
  retakeId: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<RetakeResponse> {
  const response = await axiosInstance.post(
    RETAKE_BY_ID(projectId, retakeId, FINISH_RECORD_URL),
  );
  return response.data;
}

export async function recordCheck(
  projectId: string,
  retakeId: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<RetakeResponse> {
  const response = await axiosInstance.post(
    RETAKE_BY_ID(projectId, retakeId, RECORD_CHECK_URL),
  );
  return response.data;
}

export async function recordReject(
  projectId: string,
  retakeId: string,
  description?: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<RetakeResponse> {
  const response = await axiosInstance.post(
    RETAKE_BY_ID(projectId, retakeId, RECORD_REJECT_URL),
    description ? { description } : undefined,
  );
  return response.data;
}

export async function editionCheck(
  projectId: string,
  retakeId: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<RetakeResponse> {
  const response = await axiosInstance.post(
    RETAKE_BY_ID(projectId, retakeId, EDITION_CHECK_URL),
  );
  return response.data;
}
