import axios, { AxiosInstance } from 'axios';
import { DeliveryResponse } from './model/deliveryResponse';
import {
  UploadFileUrls,
  UploadedPart,
} from 'src/contexts/UploaderContext/uploadFileModel';
import { ProjectResponse } from '../projects/model/projectResponse';
import { PutDeliveryRequest } from './model/putDeliveryRequest';
import { PostDeliveryRequest } from './model/postDeliveryRequest';
import { DeliveryFilters } from './model/deliveryFilters';

const API_URL = process.env.REACT_APP_API_URL;
const DELIVERIES_URL = (projectId: string, standardId: string, rest = '') =>
  `${API_URL}/projects/${projectId}/${standardId}/deliveries${rest}`;
const DELIVERY_BY_ID = (
  projectId: string,
  standardId: string,
  id: string,
  rest = '',
) => `${DELIVERIES_URL(projectId, standardId)}/${id}${rest}`;
const UPLOAD_FILE_URL = `/upload`;
const DELIVER_URL = `/deliver`;
const FINISH_URL = `/finish`;
const PUBLISHER_AUDIOS_URL = (
  projectId: string,
  standardId: string,
  rest = '',
) =>
  `${API_URL}/projects/${projectId}/${standardId}/deliveries/publisher-audios${rest}`;

export async function createDelivery(
  projectId: string,
  standardId: string,
  request: PostDeliveryRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<DeliveryResponse> {
  const response = await axiosInstance.post(
    DELIVERIES_URL(projectId, standardId),
    request,
  );
  return response.data;
}

export async function getDeliveries(
  projectId: string,
  standardId: string,
  axiosInstance: AxiosInstance = axios.create(),
  filters?: DeliveryFilters,
): Promise<DeliveryResponse[]> {
  const response = await axiosInstance.get(
    DELIVERIES_URL(projectId, standardId),
    {
      params: filters,
    },
  );
  return response.data;
}

export async function getUploadDeliveryFileUrls(
  projectId: string,
  standardId: string,
  id: string,
  filename: string,
  parts: number,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<UploadFileUrls> {
  const response = await axiosInstance.post(
    DELIVERY_BY_ID(projectId, standardId, id, UPLOAD_FILE_URL),
    {
      name: filename,
      parts,
    },
  );
  return response.data;
}

export async function completeUploadDeliveryFile(
  projectId: string,
  standardId: string,
  editionId: string,
  key: string,
  uploadId: string,
  parts: UploadedPart[],
  axiosInstance: AxiosInstance = axios.create(),
): Promise<DeliveryResponse> {
  const response = await axiosInstance.put(
    DELIVERY_BY_ID(projectId, standardId, editionId, UPLOAD_FILE_URL),
    {
      key,
      uploadId,
      parts,
    },
  );
  return response.data;
}

export async function deliverProject(
  projectId: string,
  standardId: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<ProjectResponse> {
  const response = await axiosInstance.post(
    DELIVERIES_URL(projectId, standardId, DELIVER_URL),
  );
  return response.data;
}

export async function finishProject(
  projectId: string,
  standardId: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<ProjectResponse> {
  const response = await axiosInstance.post(
    DELIVERIES_URL(projectId, standardId, FINISH_URL),
  );
  return response.data;
}

export async function updateDelivery(
  projectId: string,
  standardId: string,
  deliveryId: string,
  delivery: PutDeliveryRequest,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<DeliveryResponse> {
  const response = await axiosInstance.put(
    DELIVERY_BY_ID(projectId, standardId, deliveryId),
    delivery,
  );
  return response.data;
}

export async function getPublisherVersions(
  projectId: string,
  standardId: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<number[]> {
  const response = await axiosInstance.get(
    PUBLISHER_AUDIOS_URL(projectId, standardId, `/versions`),
  );
  return response.data;
}

export async function downloadDeliveryAudio(
  projectId: string,
  standardId: string,
  id: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<string> {
  const response = await axiosInstance.get(
    PUBLISHER_AUDIOS_URL(projectId, standardId, `/${id}/download`),
  );
  return response.data;
}

export async function getPublisherDeliveriesNewVersion(
  projectId: string,
  standardId: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<DeliveryResponse[]> {
  const response = await axiosInstance.get(
    PUBLISHER_AUDIOS_URL(projectId, standardId, `/new-version`),
  );
  return response.data;
}

export async function getPublisherDeliveriesFinalVersion(
  projectId: string,
  standardId: string,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<DeliveryResponse[]> {
  const response = await axiosInstance.get(
    PUBLISHER_AUDIOS_URL(projectId, standardId, `/final-version`),
  );
  return response.data;
}

export async function createPublisherReviews(
  projectId: string,
  standardId: string,
  version: number,
  axiosInstance: AxiosInstance = axios.create(),
): Promise<DeliveryResponse[]> {
  const response = await axiosInstance.post(
    PUBLISHER_AUDIOS_URL(projectId, standardId, `/${version}/create-reviews`),
  );
  return response.data;
}
