import { SvgIcon } from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutlineTwoTone';
import SecurityIcon from '@mui/icons-material/SecurityTwoTone';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenterTwoTone';
import AssessmentIcon from '@mui/icons-material/AssessmentTwoTone';
import EngineeringIcon from '@mui/icons-material/EngineeringTwoTone';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumberedTwoTone';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import {
  Ability,
  PolicyReadAbility,
  ProfileReadAbility,
  UserReadAbility,
} from 'src/contexts/AbilityContext/ability';
import { ProjectReadAbility } from 'src/contexts/AbilityContext/abilities/projects.abilities';
import { StandardReadAbility } from 'src/contexts/AbilityContext/abilities/standards.abilities';
import { SerieReadAbility } from 'src/contexts/AbilityContext/abilities/series.abilities';
import { ReportsReadAbility } from 'src/contexts/AbilityContext/abilities/reports.abilites';
import { ClientsReadAbility } from 'src/contexts/AbilityContext/abilities/clients.abilities';

export interface MenuItem {
  link?: string;
  icon?: typeof SvgIcon;
  badge?: string;
  name: string;
  ability?: Ability;
}

export interface MenuItems {
  items: MenuItem[];
  header: string;
}

const menuItems: MenuItems[] = [
  {
    header: 'audiobooks',
    items: [
      {
        name: 'projects',
        icon: BusinessCenterIcon,
        link: '/projects',
        ability: ProjectReadAbility,
      },
      // {
      //   name: 'rooms',
      //   icon: DoorBackIcon,
      //   link: '/rooms',
      //   ability: RoomReadAbility,
      // },
      {
        name: 'standards',
        icon: Grid3x3Icon,
        link: '/standards',
        ability: StandardReadAbility,
      },
      {
        name: 'series',
        icon: FormatListNumberedIcon,
        link: '/series',
        ability: SerieReadAbility,
      },
    ],
  },
  {
    header: 'reports',
    items: [
      {
        name: 'reports',
        icon: AssessmentIcon,
        link: '/reports',
        ability: ReportsReadAbility,
      },
    ],
  },
  {
    header: 'security',
    items: [
      {
        name: 'policies',
        icon: SecurityIcon,
        link: '/security/policies',
        ability: PolicyReadAbility,
      },
      {
        name: 'users',
        icon: PersonOutlineIcon,
        link: '/security/users',
        ability: UserReadAbility,
      },
      {
        name: 'profiles',
        icon: EngineeringIcon,
        link: '/security/profiles',
        ability: ProfileReadAbility,
      },
    ],
  },
  {
    header: 'clients',
    items: [
      {
        name: 'clients',
        icon: PeopleOutlinedIcon,
        link: '/clients',
        ability: ClientsReadAbility,
      },
    ],
  },
  // {
  //   header: 'about',
  //   items: [{ name: 'faq', icon: ContactSupportIcon, link: '/faq' }],
  // },
];

export default menuItems;
