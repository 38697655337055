import { SvgIcon } from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Ability } from 'src/contexts/AbilityContext/ability';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import AudiotrackRoundedIcon from '@mui/icons-material/AudiotrackRounded';
import MicRoundedIcon from '@mui/icons-material/MicRounded';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import RecordVoiceOverOutlinedIcon from '@mui/icons-material/RecordVoiceOverOutlined';
import RateReviewRoundedIcon from '@mui/icons-material/RateReviewRounded';
import UnarchiveRoundedIcon from '@mui/icons-material/UnarchiveRounded';
import { ProjectReadAbility } from 'src/contexts/AbilityContext/abilities/projects.abilities';
import { RecordingReadAbility } from 'src/contexts/AbilityContext/abilities/recordings.abilities';
import { EditionReadAbility } from 'src/contexts/AbilityContext/abilities/editions.abilities';
import { ReviewReadAbility } from 'src/contexts/AbilityContext/abilities/reviews.abilities';
import { DeliveryReadAbility } from 'src/contexts/AbilityContext/abilities/deliveries.abilities';
import { SamplesReadAbility } from 'src/contexts/AbilityContext/abilities/samples.abilities';
import { CastingsReadAbility } from 'src/contexts/AbilityContext/abilities/castings.abilities';
import { PublisherDeliveryReadAbility } from 'src/contexts/AbilityContext/abilities/publisher_delivery.abilities';
import { PublisherReviewReadAbility } from 'src/contexts/AbilityContext/abilities/publisher_reviews.abilities';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { ProjectStatus } from 'src/services/projects/model/projectEnums';
import { FileViewAbility } from 'src/contexts/AbilityContext/abilities/files.abilities';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import { ContractViewAbility } from 'src/contexts/AbilityContext/abilities/contracts.abilities';

export interface MenuItem {
  link?: string;
  tab?: string;
  icon?: typeof SvgIcon;
  badge?: string;
  name: string;
  ability?: Ability;
  disabled?: boolean;
}

export interface ProjectMenuItems {
  items: MenuItem[];
  header: string;
}

const projectMenuItems = (projectId: string): ProjectMenuItems[] => {
  const { projectStatus } = SidebarContext();
  return [
    {
      header: '',
      items: [
        {
          name: 'projects',
          icon: ArrowBackRoundedIcon,
          link: `projects/`,
          ability: ProjectReadAbility,
        },
      ],
    },
    {
      header: 'contract',
      items: [
        {
          name: 'contract',
          icon: HandshakeOutlinedIcon,
          link: `projects/${projectId}/contract`,
          tab: 'contracts',
          ability: ContractViewAbility,
        },
      ],
    },
    {
      header: 'pre_production',
      items: [
        {
          name: 'files',
          icon: FolderRoundedIcon,
          link: `projects/${projectId}/files`,
          tab: 'files',
          ability: FileViewAbility,
        },
        {
          name: 'samples',
          icon: AudiotrackRoundedIcon,
          link: `projects/${projectId}/samples`,
          ability: SamplesReadAbility,
          tab: 'samples',
        },
        {
          name: 'castings',
          icon: RecordVoiceOverOutlinedIcon,
          link: `projects/${projectId}/castings`,
          ability: CastingsReadAbility,
          tab: 'castings',
        },
      ],
    },
    {
      header: 'production',
      items: [
        {
          name: 'recordings',
          icon: MicRoundedIcon,
          link: `projects/${projectId}/recordings`,
          ability: RecordingReadAbility,
          tab: 'recordings',
          disabled: projectStatus === ProjectStatus.PENDING_FILES,
        },
        {
          name: 'editions',
          icon: DriveFileRenameOutlineOutlinedIcon,
          link: `projects/${projectId}/editions`,
          ability: EditionReadAbility,
          tab: 'editions',
          disabled: projectStatus === ProjectStatus.PENDING_FILES,
        },
        {
          name: 'reviews',
          icon: GradingOutlinedIcon,
          link: `projects/${projectId}/reviews`,
          ability: ReviewReadAbility,
          tab: 'reviews',
          disabled: projectStatus === ProjectStatus.PENDING_FILES,
        },
        {
          name: 'deliveries',
          icon: LocalShippingOutlinedIcon,
          link: `projects/${projectId}/deliveries`,
          ability: DeliveryReadAbility,
          tab: 'deliveries',
          disabled: projectStatus === ProjectStatus.PENDING_FILES,
        },
      ],
    },
    {
      header: 'publisher',
      items: [
        {
          name: 'publisher_deliveries',
          icon: UnarchiveRoundedIcon,
          link: `projects/${projectId}/publisher-deliveries`,
          ability: PublisherDeliveryReadAbility,
          tab: 'publisher-deliveries',
          disabled:
            projectStatus !== ProjectStatus.READY_FOR_DELIVERY &&
            projectStatus !== ProjectStatus.ENDED,
        },
        {
          name: 'publisher_reviews',
          icon: RateReviewRoundedIcon,
          link: `projects/${projectId}/publisher-reviews`,
          ability: PublisherReviewReadAbility,
          tab: 'publisher-reviews',
          disabled:
            projectStatus !== ProjectStatus.READY_FOR_DELIVERY &&
            projectStatus !== ProjectStatus.ENDED,
        },
      ],
    },
  ];
};

export default projectMenuItems;
