import { useRef, useState } from 'react';

import { NavLink } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  List,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';

import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenIcon from '@mui/icons-material/LockOpenTwoTone';
import AccountTreeIcon from '@mui/icons-material/AccountTreeTwoTone';
import GroupAddIcon from '@mui/icons-material/GroupAddTwoTone';
import { useAuth } from 'src/contexts/AuthContext';
import { useTranslation } from 'react-i18next';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`,
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`,
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`,
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.primary.main};
        display: block;
`,
);

function HeaderUserbox() {
  const auth = useAuth();
  const user = auth.user;
  const { t } = useTranslation(['account']);

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar
          variant="rounded"
          sx={{
            width: 30,
            height: 30,
          }}
        />
        <Hidden lgDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.email}</UserBoxLabel>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="rounded" />
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.email}</UserBoxLabel>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItemButton
            to="/account/settings"
            component={NavLink}
            onClick={handleClose}
          >
            <AccountTreeIcon fontSize="small" />
            <ListItemText primary={t('settings')} sx={{ ml: 1 }} />
          </ListItemButton>
          <ListItemButton
            to="/account/invitations"
            component={NavLink}
            onClick={handleClose}
          >
            <GroupAddIcon fontSize="small" />
            <ListItemText primary={t('invitations')} sx={{ ml: 1 }} />
          </ListItemButton>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" component={NavLink} to="/logout" fullWidth>
            <LockOpenIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
