import { Badge, Button, ListItem } from '@mui/material';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';

interface SidebarMenuItemProps {
  link?: string;
  icon?: any;
  badge?: string;
  open?: boolean;
  name: string;
}

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  link = '#',
  icon: Icon,
  badge,
  name,
  ...rest
}) => {
  const { closeSidebar } = SidebarContext();

  return (
    <ListItem component="div" key={name} {...rest}>
      <Button
        disableRipple
        onClick={() => {
          closeSidebar();
        }}
        to={link}
        startIcon={Icon && <Icon />}
        component={NavLink}
      >
        {name}
        {badge && <Badge badgeContent={badge} />}
      </Button>
    </ListItem>
  );
};

export default SidebarMenuItem;
