import { Ability, Action, Subject } from '../ability';

class ReportsAbility implements Ability {
  subject: Subject;
  action: Action;

  constructor(action: Action) {
    this.subject = 'Reports';
    this.action = action;
  }
}
export const ReportsReadAbility = new ReportsAbility('read');
export const ReportProjectReadAbility = new ReportsAbility(
  'viewProjectReport' as Action,
);
export const ReportUsersGanttReadAbility = new ReportsAbility(
  'viewUsersGanttReport' as Action,
);
export const ReportChartsProgressReadAbility = new ReportsAbility(
  'viewChartsProgressReport' as Action,
);

export const reportAbilities = [
  ReportsReadAbility,
  ReportProjectReadAbility,
  ReportUsersGanttReadAbility,
  ReportChartsProgressReadAbility,
];
export default reportAbilities;
