import { useRoutes } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider } from '@emotion/react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AuthProvider } from 'src/contexts/AuthContext';
import { SnackbarProvider } from 'notistack';
import NiceModal from '@ebay/nice-modal-react';
import ThemeProvider from './theme/ThemeProvider';
import { createEmotionCache } from './utils/create-emotion-cache';
import { APIProvider } from './contexts/APIContext';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  ApolloLink,
} from '@apollo/client';
import { AUTH_TYPE, AuthOptions, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import routes from './router';

import moment from 'moment-timezone';
import 'moment-duration-format';
import { UploaderProvider } from './contexts/UploaderContext';
import { LoaderProvider } from './contexts/LoaderContext';
import { AbilityProvider } from './contexts/AbilityContext';
import SnackbarCloseButton from './components/notifications/SnackbarCloseButton';
import './style/app.scss';
import { useEffect, useState } from 'react';
import { DatabaseProvider } from './contexts/DatabaseContext';
import { EventsProvider } from './contexts/EventsContext';

export default function App() {
  const emotionCache = createEmotionCache();
  const content = useRoutes(routes);
  const [userLocale, setUserLocale] = useState<string>();

  const url = process.env.REACT_APP_APPSYNC_ENDPOINT as string;
  const httpLink = new HttpLink({ uri: url });

  const auth: AuthOptions = {
    type: AUTH_TYPE.API_KEY,
    apiKey: process.env.REACT_APP_APPSYNC_API_KEY as string,
  };

  const link = ApolloLink.from([
    createAuthLink({ url, region: 'eu-west-1', auth }),
    createSubscriptionHandshakeLink(
      { url, region: 'eu-west-1', auth },
      httpLink,
    ),
  ]);

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });

  const setLocale = () => {
    const userLocale = window.navigator.language.toLowerCase();
    if (!moment.locales().includes(userLocale)) {
      const userLocaleWithoutRegion = userLocale.split('-')[0];
      if (userLocaleWithoutRegion === 'es') {
        import(`moment/locale/${userLocaleWithoutRegion}`);
        setUserLocale('es');
      } else {
        const userLocaleFallback = 'en-gb';
        import(`moment/locale/${userLocaleFallback}`);
        setUserLocale('en');
      }
      return;
    }
    import(`moment/locale/${userLocale}`);
    setUserLocale(userLocale);
  };

  useEffect(() => {
    setLocale();
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider>
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          adapterLocale={userLocale}
        >
          <SnackbarProvider
            maxSnack={6}
            action={(snackKey) => <SnackbarCloseButton snackKey={snackKey} />}
          >
            <LoaderProvider>
              <ApolloProvider client={client}>
                <AuthProvider>
                  <AbilityProvider>
                    <DatabaseProvider databaseName="EARS">
                      <EventsProvider>
                        <APIProvider>
                          <UploaderProvider>
                            <NiceModal.Provider>
                              <CssBaseline />
                              {content}
                            </NiceModal.Provider>
                          </UploaderProvider>
                        </APIProvider>
                      </EventsProvider>
                    </DatabaseProvider>
                  </AbilityProvider>
                </AuthProvider>
              </ApolloProvider>
            </LoaderProvider>
          </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </CacheProvider>
  );
}
