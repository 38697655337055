import { Ability, Action, Subject } from '../ability';

class StandardAbility implements Ability {
  subject: Subject;
  action: Action;

  constructor(action: Action) {
    this.subject = 'Standards';
    this.action = action;
  }
}

export const StandardReadAbility = new StandardAbility('read');
export const StandardCreateAbility = new StandardAbility('create');
export const StandardUpdateAbility = new StandardAbility('update');

export const standardAbilities = [StandardReadAbility, StandardCreateAbility];
export default standardAbilities;
