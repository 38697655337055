import { Ability, Action, Subject } from '../ability';

class ReviewAbility implements Ability {
  subject: Subject;
  action: Action;

  constructor(action: Action) {
    this.subject = 'Reviews';
    this.action = action;
  }
}

export const ReviewReadAbility = new ReviewAbility('read');
export const ReviewListenAbility = new ReviewAbility('listen' as Action);
export const ReviewFinalizeAbility = new ReviewAbility('finalize' as Action);
export const ReviewSendRetakesAbility = new ReviewAbility(
  'sendRetakes' as Action,
);
export const reviewAbilities = [
  ReviewReadAbility,
  ReviewListenAbility,
  ReviewFinalizeAbility,
  ReviewSendRetakesAbility,
];
export default reviewAbilities;
