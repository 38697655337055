import { Ability, Action, Subject } from '../ability';

class ClientsAbility implements Ability {
  subject: Subject;
  action: Action;

  constructor(action: Action) {
    this.subject = 'Clients';
    this.action = action;
  }
}

export const ClientsReadAbility = new ClientsAbility('read');
export const ClientsCreateAbility = new ClientsAbility('create');
export const ClientsUpdateAbility = new ClientsAbility('update');
export const ClientsDeleteAbility = new ClientsAbility('delete');

export const ClientsAbilities = [
  ClientsReadAbility,
  ClientsCreateAbility,
  ClientsUpdateAbility,
  ClientsDeleteAbility,
];

export default ClientsAbilities;
