import axios from 'axios';
import { LoginResponse } from './model/login.response';
import { ValidateOTPResponse } from './model/validateOTP.response';
import { ChangePasswordRequest } from './model/changePasswordRequest';
import { RecoverPasswordRequest } from './model/recoverPasswordRequest';
import { ResetPasswordRequest } from './model/resetPasswordRequest';

const API_URL = process.env.REACT_APP_API_URL;
const AUTH_URL = `${API_URL}/auth`;
const LOGIN_URL = `${AUTH_URL}/login`;
const VALIDATE_OTP_URL = `${AUTH_URL}/validate2FA`;
const CHANGE_PASSWORD_URL = `${AUTH_URL}/password`;

export async function login(
  email: string,
  password: string,
): Promise<LoginResponse> {
  const response = await axios.post(LOGIN_URL, { email, password });
  return response.data;
}

export async function validateOTP(
  token: string,
  authToken: string,
): Promise<ValidateOTPResponse> {
  const response = await axios.post(
    VALIDATE_OTP_URL,
    { token },
    { headers: { Authorization: `Bearer ${authToken}` } },
  );
  return response.data;
}

export async function changePassword(
  request: ChangePasswordRequest,
  authToken: string,
): Promise<void> {
  await axios.put(CHANGE_PASSWORD_URL, request, {
    headers: { Authorization: `Bearer ${authToken}` },
  });
}

export async function recoverPassword(
  request: RecoverPasswordRequest,
): Promise<void> {
  await axios.post(`${LOGIN_URL}/recover-password`, request);
}

export async function resetPassword(
  request: ResetPasswordRequest,
): Promise<void> {
  await axios.post(`${LOGIN_URL}/reset-password`, request);
}
