import { Ability, Action, Subject } from '../ability';

class SerieAbility implements Ability {
  subject: Subject;
  action: Action;

  constructor(action: Action) {
    this.subject = 'Series';
    this.action = action;
  }
}

export const SerieReadAbility = new SerieAbility('read');
export const SerieCreateAbility = new SerieAbility('create');
export const SerieUpdateAbility = new SerieAbility('update');

export const SerieAbilities = [
  SerieReadAbility,
  SerieCreateAbility,
  SerieUpdateAbility,
];
export default SerieAbilities;
