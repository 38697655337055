import { Ability, Action, Subject } from '../ability';

class ProjectAbility implements Ability {
  subject: Subject;
  action: Action;

  constructor(action: Action) {
    this.subject = 'Projects';
    this.action = action;
  }
}

export const ProjectReadAbility = new ProjectAbility('read');
export const ProjectCreateAbility = new ProjectAbility('create');
export const ProjectUpdateAbility = new ProjectAbility('update');

export const projectAbilities = [
  ProjectReadAbility,
  ProjectCreateAbility,
  ProjectUpdateAbility,
];

export default projectAbilities;
